<template>
	<g>
		<transition name="quickfade">
			<g>
				<foreignObject
					v-if="show_form"
					x="-400"
					y="-400"
					width="800"
					height="900"
				>
					<div class="anxtopic">
						{{ topic + "&nbsp;" }}
					</div>
				</foreignObject>
				<foreignObject
					v-if="show_form"
					x="-400"
					y="-330"
					width="800"
					height="900"
				>
					<div v-if="item_text" class="anxquestionbox">
						{{ item_text }}
						<div
							v-if="alt1"
							class="anxbutton"
							style="background-color: #ffffff33"
							v-on:click="log_item(1)"
						>
							{{ alt1 }}
						</div>
						<div
							v-if="alt2"
							class="anxbutton"
							style="background-color: #ffffff33"
							v-on:click="log_item(2)"
						>
							{{ alt2 }}
						</div>
						<div
							v-if="alt3"
							class="anxbutton"
							style="background-color: #ffffff33"
							v-on:click="log_item(3)"
						>
							{{ alt3 }}
						</div>
						<div
							v-if="alt4"
							class="anxbutton"
							style="background-color: #ffffff33"
							v-on:click="log_item(4)"
						>
							{{ alt4 }}
						</div>
						<div
							v-if="alt5"
							class="anxbutton"
							style="background-color: #ffffff33"
							v-on:click="log_item(5)"
						>
							{{ alt5 }}
						</div>
					</div>
				</foreignObject>
				<image
					v-if="show_image"
					x="100"
					y="-140"
					xlink:href="/img/diagram.png"
				/>
				<g v-if="anxidx < 22">
					<rect
						stroke="#000000ff"
						stroke-width="3"
						fill="#00000022"
						x="-220"
						y="300"
						width="440"
						height="20"
						rx="5"
					/>
					<rect
						v-if="anxidx > 0"
						stroke="#000000ff"
						stroke-width="3"
						fill="#FFFFFFFF"
						x="-220"
						y="300"
						:width="anxidx * 20"
						height="20"
						rx="5"
					/>
				</g>
				<g v-if="anxidx >= 22">
					<rect
						stroke="#000000ff"
						stroke-width="3"
						fill="#FFFFFFCC"
						x="-240"
						y="-280"
						width="484"
						height="100"
						rx="5"
					/>
					<text
						x="0"
						y="-230"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="30"
						fill="#000000FF"
						>Klart för den här gången!</text
					>
				</g>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import persist from "@/persist.js";

const anxitems = [
	// ----- tycker om
	"1. Matematikuppgifter är enkla för mig",
	"2. Jag lär mig matematik snabbt",
	"3. Jag är bra på matematik",
	"4. Jag tycker om matematik",
	"5. Jag är intresserad av matematik",
	"6. Jag ser fram emot matematiklektionerna",

	// ----- oro
	"1. Jag oroar mig för att uppgifterna i matematikboken ska vara för svåra för mig",
	"2. Jag oroar mig för att jag inte ska klara av matematikläxorna",
	"3. Jag oroar mig för att jag inte ska kunna lösa räkneuppgifter på matematiklektionen",
	// (BILD)
	"4. Jag oroar mig för att jag ska få frågor om att jag ska läsa av ett diagram på matematiklektionen (se exempel nedan)",
	"5. Jag oroar mig för att läraren ska titta på när jag löser en matematikuppgift i matteboken",
	"6. Jag oroar mig för att läraren ska be mig berätta lösningen på en matematikuppgift inför klassen",
	"7. Jag oroar mig för att jag inte ska hinna göra klart matematikprovet",
	"8. Jag oroar mig för att jag inte ska komma på hur jag ska lösa en uppgift på matematikprovet",

	// ----- nervös
	'1. Hur nervös känner du dig när du läser den här meningen i matteboken: "Räkna om från centimeter till meter"',
	"2. Hur nervös känner du dig när du ser en hel sida med räkneuppgifter i matematikboken",
	"3. Hur nervös känner du dig när du får en läxa i slutet av matematiklektionen",
	"4. Hur nervös känner du dig när du är på väg till mattelektionen",
	"5. Hur nervös känner du dig när du ska addera 976+777+558 skriftligt på matematiklektionen",
	"6. Hur nervös känner du dig när din lärare frågar dig vad 3 gånger 58 blir",
	// (BILD)
	"7. Hur nervös känner du dig när läraren vill att du ritar ett stapeldiagram inför klassen (se exempel nedan)",
	"8. Hur nervös känner du dig när du tänker på ett matematikprov kvällen före provet",
];

export default {
	name: "choke_anxform",
	//genväg-alias:
	local: store.state.anxform,
	components: {},
	data: () => {
		return {
			start_time: 0,
			initial_session: 0,
			initial_anxidx: 0,
			initial_anxforms_done: 0,
			beforeUnloadHandler: null,
			anxidx: 0,
			items: [],
			answers: [],
			rt: [],
			show_form: false,
			topic: "",
			item_text: "",
			show_image: false,
			alt1: "",
			alt2: "",
			alt3: "",
			alt4: "",
			alt5: "",
			item_start_time: 0,
		};
	},
	//props: ['stim_param'],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	methods: {
		end_test() {
			this.show_form = false;

			this.saveSessionResults();
			store.commit("setTaskMode", "sessionDone");
			window.removeEventListener(
				"beforeunload",
				this.beforeUnloadHandler
			);
			store.commit("setTaskMode", "sessionDone");
		},
		abortAndSave() {
			this.saveSessionResults();
		},
		saveSessionResults() {
			this.maybe_next_session();
			if (this.anxidx <= this.initial_anxidx) return; // inget att spara
			//console.log("save responses");

			const total_time = Date.now() - this.start_time;
			let session = this.initial_session;
			persist.log("anxform", {
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
				ans: this.answers,
				rt: this.rt,
				s: session,
			});
		},
		maybe_next_session() {
			if (this.anxidx >= anxitems.length) {
				persist.set_progress_data("anxidx", 0); // nollställ inför nästa enkät
				//persist.set_progress_data("session", this.initial_session + 1);
				persist.set_progress_data(
					"anxforms_done",
					this.initial_anxforms_done + 1
				);
				//console.log("done! reset anxidx");
			} else if (this.anxidx > this.initial_anxidx) {
				// spara index för ofärdig enkät
				persist.set_progress_data("anxidx", this.anxidx);
				//console.log("update anxidx: " + this.anxidx);
			}
		},
		init_item() {
			//console.log("anxidx: " + this.anxidx);
			if (this.anxidx < anxitems.length) {
				this.show_image = false;
				if (this.anxidx == 0)
					this.topic =
						"Nu kommer 6 frågor om vad du tycker om matematik";
				else if (this.anxidx == 6)
					this.topic =
						"Nu kommer 8 frågor till om matematik. Nu frågar vi om det gör dig orolig";
				else if (this.anxidx == 14)
					this.topic =
						"Nu kommer de sista 8 frågorna om matematik. Nu frågar vi om det gör dig nervös";
				else this.topic = "";

				if (this.topic) {
					setTimeout(() => {
						this.init_item_part2();
					}, 3000);
				} else {
					this.init_item_part2();
				}
			} else {
				this.item_text = "";
				this.show_image = false;
				this.alt1 = "";
				this.alt2 = "";
				this.alt3 = "";
				this.alt4 = "";
				this.alt5 = "";
			}
		},
		init_item_part2() {
			this.item_text = anxitems[this.anxidx];
			if (this.anxidx < 6) {
				this.alt1 = "Falskt";
				this.alt2 = "Oftast falskt";
				this.alt3 = "Ibland sant, ibland falskt";
				this.alt4 = "Oftast sant";
				this.alt5 = "Sant";
			} else if (this.anxidx < 14) {
				this.alt1 = "Inte orolig alls";
				this.alt2 = "Lite orolig";
				this.alt3 = "Ganska orolig";
				this.alt4 = "Mycket orolig";
				this.alt5 = "";
			} else {
				this.alt1 = "Inte alls nervös";
				this.alt2 = "Lite nervös";
				this.alt3 = "Ganska nervös";
				this.alt4 = "Mycket nervös";
				this.alt5 = "";
			}

			this.show_image = this.anxidx == 9 || this.anxidx == 20;

			this.item_start_time = Date.now();
		},
		log_item(alt) {
			const rt = (Date.now() - this.item_start_time) / 1000.0;
			this.answers[this.anxidx] = alt;
			this.rt[this.anxidx] = rt;

			this.item_text = "";
			this.show_image = false;
			this.alt1 = "";
			this.alt2 = "";
			this.alt3 = "";
			this.alt4 = "";
			this.alt5 = "";

			this.anxidx += 1;
			if (this.anxidx < anxitems.length) {
				setTimeout(() => {
					this.init_item();
				}, 100);
			} else {
				this.end_test();
			}
		},
	},
	mounted() {
		this.start_time = Date.now();
		this.beforeUnloadHandler = () => {
			//console.log("before unload");
			this.abortAndSave();
			// Optionally, show a confirmation dialog
			// event.returnValue = 'Are you sure you want to leave?';
		};
		window.addEventListener("beforeunload", this.beforeUnloadHandler);

		const session = this.$store.state.progress.session || 0;
		this.initial_session = session;

		this.initial_anxforms_done =
			this.$store.state.progress.anxforms_done || 0;

		this.initial_anxidx = this.$store.state.progress.anxidx || 0;
		this.anxidx = this.initial_anxidx;
		//console.log("initial_anxforms_done: " + this.initial_anxforms_done);
		//console.log("initial_session: " + this.initial_session);
		//console.log("initial_anxidx: " + this.initial_anxidx);
		if (this.initial_anxidx < anxitems.length) {
			for (var i; i < this.initial_anxidx; i++) {
				this.answers.push(null);
				this.rt.push(null);
			}
			// START
			this.init_item();
			this.show_form = true;
		} else {
			this.end_test();
		}
	},
};
</script>

<style lang="stylus">
.anxtopic {
	cursor: default;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 10px;
	padding-bottom: 3px;
	background-color: #44444422;
	border-radius: 25px;
	user-select: none;
	text-align: center;
	text-text-justify: auto;
	font-size: 20px;
	font-family: sans-serif;
	color: #FFFFFF;
	text-shadow: -1px -1px 0 #666, 0 -1px 0 #666, 1px -1px 0 #666, 1px 0 0 #666, 1px 1px 0 #666, 0 1px 0 #666, -1px 1px 0 #666, -1px 0 0 #666, -1px -1px 12px #666;
}

.anxquestionbox {
	cursor: default;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 10px;
	padding-bottom: 3px;
	background-color: #44444466;
	border-radius: 25px;
	user-select: none;
	text-align: left;
	text-text-justify: auto;
	font-size: 24px;
	font-family: sans-serif;
	color: #FFDD88;
	text-shadow: -1px -1px 0 #666, 0 -1px 0 #666, 1px -1px 0 #666, 1px 0 0 #666, 1px 1px 0 #666, 0 1px 0 #666, -1px 1px 0 #666, -1px 0 0 #666, -1px -1px 12px #666;
}

.anxbutton {
	cursor: pointer;
	text-align: left;
	margin: 0.25em;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: 0px;
	margin-right: 300px;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #FFFFFF22;
	border-radius: 25px;
	border: 8px solid #FFFFFF;
	user-select: none;
	font-size: 20px;
	font-family: sans-serif;
	color: #FFFFFF;
	letter-spacing: 0.05em;
	text-shadow: -1px -1px 0 #aaa, 0 -1px 0 #aaa, 1px -1px 0 #aaa, 1px 0 0 #aaa, 1px 1px 0 #aaa, 0 1px 0 #aaa, -1px 1px 0 #aaa, -1px 0 0 #aaa, -1px -1px 12px #aaa;
}
</style>
