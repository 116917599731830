<template>
	<g>
		<transition name="fade">
			<foreignObject x="-600" y="-440" width="1200" height="1000">
				<form @submit.prevent="doContinue">
					<div class="bgi">
						<div class="lab">Jag heter</div>
						<span class="bg2">
							<input
								type="text"
								id="fornamn"
								name="fornamn"
								v-model="fornamn"
								class="bgf"
								placeholder="Förnamn"
								spellcheck="false"
								autocomplete="off"
								autocorrect="off"
								autofocus="true"
								required="true"
							/>
						</span>
						<span class="bg2">
							<input
								type="text"
								id="efternamn"
								name="efternamn"
								v-model="efternamn"
								class="bgf"
								placeholder="Efternamn"
								spellcheck="false"
								autocomplete="off"
								autocorrect="off"
								autofocus="true"
								required="true"
							/>
						</span>
					</div>
					<div class="bgi">
						<select
							id="choices"
							name="alder"
							v-model="alder"
							class="bgf"
							required
						>
							<option value="Min ålder är" selected disabled>
								Min ålder är
							</option>
							<option value="8">8 år</option>
							<option value="9">9 år</option>
							<option value="10">10 år</option>
							<option value="11">11 år</option>
							<option value="12">12 år</option>
							<option value="13">13 år</option>
							<option value="14">14 år</option>
							<option value="15">15 år</option>
						</select>
					</div>
					<div class="bgi">
						<input
							type="radio"
							id="option1"
							name="kon"
							v-model="kon"
							value="F"
							required
						/>
						<label for="option1" class="bgf">Jag är flicka</label
						><br />
						<input
							type="radio"
							id="option2"
							name="kon"
							v-model="kon"
							value="P"
							required
						/>
						<label for="option2" class="bgf">Jag är pojke</label
						><br />
						<input
							type="radio"
							id="option3"
							name="kon"
							v-model="kon"
							value="-"
							required
						/>
						<label for="option3" class="bgf">Vill ej ange</label
						><br />
					</div>
					<div class="bgi">
						<div class="lab">Jag går i</div>
						<span class="bg2">
							<input
								type="text"
								id="klass"
								name="klass"
								v-model="klass"
								class="bgf"
								placeholder="Klass"
								spellcheck="false"
								autocomplete="off"
								autocorrect="off"
								autofocus="true"
								required="true"
							/>
						</span>
						<span class="bg2">
							<input
								type="text"
								id="skola"
								name="skola"
								v-model="skola"
								class="bgf"
								placeholder="Skola"
								spellcheck="false"
								autocomplete="off"
								autocorrect="off"
								autofocus="true"
								required="true"
							/>
						</span>
					</div>
					<div class="bgi">
						<button class="link" type="submit">Gå vidare</button>
					</div>
				</form>
			</foreignObject>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import persist from "@/persist.js";

export default {
	name: "bgid",
	components: {},
	data: () => {
		return {
			fornamn: null,
			efternamn: null,
			alder: "Min ålder är",
			kon: null,
			klass: null,
			skola: null,
		};
	},
	methods: {
		enterKeyPushed: function (e) {
			if (e.key === "Enter") {
				this.doContinue();
			}
		},
		doContinue: function () {
			const alderInt = parseInt(this.alder);
			const alderOk = !isNaN(alderInt);

			// console.log("Förnamn: " + this.fornamn);
			// console.log("Efternamn: " + this.efternamn);
			// console.log("Ålder: " + this.alder);
			// console.log("Kön: " + this.kon);
			// console.log("Klass: " + this.klass);
			// console.log("Skola: " + this.skola);
			if (
				this.fornamn &&
				this.efternamn &&
				this.alder &&
				alderOk &&
				this.kon &&
				this.klass &&
				this.skola
			) {
				//alert("Form submitted successfully!");
				//console.log("ok!");
				const id = {
					n: this.fornamn,
					sn: this.efternamn,
					age: this.alder,
					sx: this.kon,
					sc1: this.klass,
					sc2: this.skola,
				};
				persist.set_progress_data("bgid", JSON.stringify(id));
				store.dispatch("gotoModule", "home");
			} else if (!alderOk) {
				alert("Var snäll fyll i din ålder");
			} else {
				alert("Var snäll fyll i alla uppgifter");
			}

			// const name_input = document.getElementById("loginName");
			// const pwd_input  = document.getElementById("loginPwd");
			// const name = name_input.value.toLowerCase();
			// const pwd  = pwd_input.value.toLowerCase();
			// if (name != '' && pwd != '') {
			// 	persist.login(name, pwd).then(ready => {
			// 		if (ready) {
			// 			this.$store.commit('hideError');
			// 			store.dispatch('gotoModule', 'home');
			// 		} else {
			// 			pwd_input.focus();
			// 			pwd_input.setSelectionRange(0, pwd_input.value.length);
			// 			this.$store.commit('showError', this.text.wrong_password);
			// 		}
			// 	}).catch((e) => {
			// 		/* eslint-disable no-console */
			// 		console.error('login error ' + e);
			// 		/* eslint-enable no-console */
			// 	});
			// }
			// else if (name == '') {
			// 	name_input.focus();
			// }
			// else if (pwd == '') {
			// 	pwd_input.focus();
			// }
		},
		submitForm() {
			if (this.fornamn && this.efternamn) {
				alert("Form submitted successfully!");
				//console.log(this.fornamn);
				//console.log(this.efternamn);
			} else {
				alert("Please fill out all required fields.");
			}
		},
	},
	mounted() {
		//const fields = document.getElementsByClassName("field");
		// fields[0].addEventListener("keyup", this.enterKeyPushed.bind(this)); // username
		// fields[1].addEventListener("keyup", this.enterKeyPushed.bind(this)); // password
		// const current_name = localStorage.getItem('current_name');
		// if (current_name) {
		// 	fields[0].value = current_name;
		// 	fields[0].focus();
		// 	fields[0].setSelectionRange(0, fields[0].value.length);
		// } else {
		// 	fields[0].focus();
		// }
	},
};
</script>

<style lang="stylus">
.bgi {
	cursor: default;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 10px;
	background-color: #00000011;
	border-radius: 20px;
	text-align: center;
}

.bg2 {
	cursor: default;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
}

.lab {
	cursor: default;
	margin-top: 0px;
	margin-bottom: 0px;
	text-align: center;
	font-size: 20px;
	color: #FFFFFF;
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
}

.bgf {
	cursor: text;
	font-family: serif;
	font-size: 30px;
	color: #000000;
	text-align: center;
	text-text-justify: auto;
	letter-spacing: 0.1em;
	display: inline-block;
	width: 300px;
	margin-top: 5px;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	padding-left: 40px;
	padding-right: 40px;
	background-color: #FFFFFF55;
	border-radius: 20px;
	border: 4px solid white;
}
</style>
