<template><g></g></template>

<script>
import { store } from "@/store.js";
//import lang from "@/v/anx/menu/lang.js";
//import logoutButton from "@/v/taskMenu/logoutButton.vue";

export default {
	name: "home_choke",
	components: {
		//logoutButton,
	},
	//mixins: [lang],
	computed: {
		text() {
			return 0;
			// 	return this.getLocalisedText();
		},
	},
	data: () => {
		return {};
	},
	methods: {},
	mounted() {
		//store.dispatch("gotoModule", "choke_test_multiplication");
		//store.dispatch("gotoModule", "choke_practice_multiplication");
		//store.dispatch("gotoModule", "choke_anxform");

		if (this.$store.state.progress.demo == 1) {
			//store.dispatch("gotoModule", "choke_practice_multiplication_demo");
			store.dispatch("gotoModule", "choke_test_multiplication_demo");
		} else {
			store.dispatch("gotoModule", "choke_practice_multiplication");
		}
	},
};
</script>
