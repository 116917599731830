export default {
	EPSILON: 0.001,

	/**
	 * Determine is two numbers are equal within a given margin of precision.
	 *
	 * @param {Number} first number.
	 * @param {Number} second number.
	 * @param {Number} epsilon.
	 */
	numbersEqual(first, second, epsilon = this.EPSILON) {
		if (typeof (first) !== 'number' || typeof (second) !== 'number' || typeof (epsilon) !== 'number') throw new Error("First and Second must be numbers.");
		return (first - second) < epsilon && (first - second) > -epsilon;
	},

	sum(arr) {
		if (Object.prototype.toString.call(arr) === '[object Array]') {
			var total = 0;
			for (var i = 0; i < arr.length; i++) {
				if (typeof (arr[i]) === 'number') {
					total = total + arr[i];
				} else {
					throw new Error('All elements in array must be numbers');
				}
			}
			return total;
		} else {
			throw new Error('Input must be of type Array');
		}
	},

	mean(arr) {
		var count = arr.length;
		var sum = this.sum(arr);
		return sum / count;
	},

	standardDev(arr) {
		var count = arr.length;
		var mean = this.mean(arr);
		var squaredArr = [];

		for (var i = 0; i < arr.length; i++) {
			squaredArr[i] = Math.pow((arr[i] - mean), 2);
		}

		return Math.sqrt((1 / count) * this.sum(squaredArr));
	},

	covariance(set1, set2) {
		if (set1.length === set2.length) {
			var n = set1.length;
			var total = 0;
			var sum1 = this.sum(set1);
			var sum2 = this.sum(set2);

			for (var i = 0; i < n; i++) {
				total += set1[i] * set2[i];
			}

			return (total - sum1 * sum2 / n) / n;
		} else {
			throw new Error('Array mismatch');
		}
	},

	correlation(arrX, arrY) {
		if (arrX.length === arrY.length) {
			var covarXY = this.covariance(arrX, arrY);
			var stdDevX = this.standardDev(arrX);
			var stdDevY = this.standardDev(arrY);

			return covarXY / (stdDevX * stdDevY);
		} else {
			throw new Error('Array mismatch');
		}
	},

	/**
	 * Retrieve a specified quantity of elements from an array, at random.
	 *
	 * @param {Array} set of values to select from.
	 * @param {Number} quantity of elements to retrieve.
	 * @param {Boolean} allow the same number to be returned twice.
	 * @return {Array} random elements.
	 */
	random(arr, quant, allowDuplicates) {
		if (arr.length === 0) {
			throw new Error('Empty array');
		} else if (quant > arr.length && !allowDuplicates) {
			throw new Error('Quantity requested exceeds size of array');
		}

		if (allowDuplicates === true) {
			var result = [],
				i;
			for (i = 0; i < quant; i++) {
				result[i] = arr[Math.floor(Math.random() * arr.length)];
			}
			return result;
		} else {
			return this.shuffle(arr).slice(0, quant);
		}
	},

	/**
	 * Shuffle an array, in place.
	 *
	 * @param {Array} array to be shuffled.
	 * @return {Array} shuffled array.
	 */
	shuffle(array) {
		var m = array.length,
			t, i;

		while (m) {
			i = Math.floor(Math.random() * m--);

			t = array[m];
			array[m] = array[i];
			array[i] = t;
		}

		return array;
	},

	/**
	 * Find maximum value in an array.
	 *
	 * @param {Array} array to be traversed.
	 * @return {Number} maximum value in the array.
	 */
	max(arr) {
		if (!Array.isArray(arr)) {
			throw new Error("Input must be of type Array");
		}
		var max = -Infinity,
			val;
		for (var i = 0, len = arr.length; i < len; i++) {
			val = +arr[i];
			if (max < val) {
				max = val;
			}
			// Math.max() returns NaN if one of the elements is not a number.
			if (val !== val) {
				return NaN;
			}
		}
		return max;
	},

	/**
	 * Find minimum value in an array.
	 *
	 * @param {Array} array to be traversed.
	 * @return {Number} minimum value in the array.
	 */
	min(arr) {
		if (!Array.isArray(arr)) {
			throw new Error("Input must be of type Array");
		}
		var min = +Infinity,
			val;
		for (var i = 0, len = arr.length; i < len; i++) {
			val = +arr[i];
			if (val < min) {
				min = val;
			}
			// Math.min() returns NaN if one of the elements is not a number.
			if (val !== val) {
				return NaN;
			}
		}
		return min;
	},


	/**
	 * Find median value of an array.
	 * The array will be modified (sorted)!
	 *
	 * @param {Array} array to be traversed.
	 * @return {Number} median value of the array.
	 */
	mut_array_calc_median(arr) {
		if (!Array.isArray(arr)) {
			throw new Error("Input must be of type Array");
		}
		arr.sort((a, b) => a - b);

		const mid = Math.floor(arr.length / 2);

		if (arr.length % 2 === 0) {
			// If even, return the average of the two middle numbers
			return (arr[mid - 1] + arr[mid]) / 2;
		} else {
			// If odd, return the middle number
			return arr[mid];
		}
	},

	xround(n) {
		return Math.round(n * 100) / 100;
	},


	/**
	 * Find Median Absolute Deviation (MAD) value of an array.
	 * The array will be modified (sorted)!
	 *
	 * @param {Array} array to be traversed.
	 * @return {median: Number, mad: Number} median and mad
	 */
	mut_array_median_absolute_deviation(arr) {
		if (!Array.isArray(arr)) {
			throw new Error("Input must be of type Array");
		}
		if (arr.length == 0) {
			//return [NaN, NaN];
			return ['-', '-'];
		}

		var median = this.xround(this.mut_array_calc_median(arr));

		if (arr.length == 1) {
			return [this.xround(median), 0];
		}


		var absolute_deviations = arr.map(value => Math.abs(value - median));

		var mad = this.mut_array_calc_median(absolute_deviations);
		return [this.xround(median), this.xround(mad)];
	},


	/**
	 * Determine if the number is an integer.
	 *
	 * @param {Number} the number
	 * @return {Boolean} true for int, false for not int.
	 */
	isInt(n) {
		return n % 1 === 0;
	},

	/**
	 * Calculate the divisor and modulus of two integers.
	 *
	 * @param {Number} int a.
	 * @param {Number} int b.
	 * @return {Array} [div, mod].
	 */
	divMod(a, b) {
		if (b <= 0) throw new Error("b cannot be zero. Undefined.");
		if (!this.isInt(a) || !this.isInt(b)) throw new Error("A or B are not integers.");
		return [Math.floor(a / b), a % b];
	},

	/**
	 * Factorial for some integer.
	 *
	 * @param {Number} integer.
	 * @return {Number} result.
	 */
	factorial(num) {
		if (typeof (num) !== 'number') throw new Error("Input must be a number.");
		if (num < 0) throw new Error("Input must not be negative.");
		var i = 2,
			o = 1;

		while (i <= num) {
			o *= i++;
		}

		return o;
	},


	/**
	 * Calculate the binomial coefficient (n choose k)
	 *
	 * @param {Number} available choices
	 * @param {Number} number chosen
	 * @return {Number} number of possible choices
	 */
	binomial(n, k) {

		var arr = [];

		function _binomial(n, k) {
			if (typeof (n) !== 'number' && typeof (k) !== 'number') {
				throw new Error('Input must be a number.');
			}
			if (n >= 0 && k === 0) return 1;
			if (n === 0 && k > 0) return 0;
			if (arr[n] && arr[n][k] > 0) return arr[n][k];
			if (!arr[n]) arr[n] = [];

			arr[n][k] = _binomial(n - 1, k - 1) + _binomial(n - 1, k);
			return arr[n][k];
		}

		return _binomial(n, k);
	},

	/**
	 * Calculate the permutation (n choose k)
	 *
	 * @param {Number} available choices
	 * @param {Number} number chosen
	 * @return {Number} number of ordered variations
	 */

	permutation(n, k) {
		if (n <= 0) {
			throw new Error("n cannot be less than or equal to 0.");
		}
		if (n < k) {
			throw new Error("k cannot be greater than k.");
		}
		var binomial = this.binomial(n, k);
		var permutation = binomial * this.factorial(k);
		return permutation;
	},



	differential(func, value) {
		const a = func(value - 0.001);
		const b = func(value + 0.001);

		return (b - a) / (0.002);
	}
}
