<template>
	<g>
		<countDown ref="countDown1" name="cd1" />
		<countDown ref="countDown2" name="cd2" />
		<transition name="quickfade">
			<g>
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#FFFFFF88"
					x="-300"
					y="-420"
					width="600"
					height="110"
					rx="15"
				/>
				<text
					opacity="1.0"
					x="60"
					y="-330"
					class="txt"
					text-anchor="end"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="96"
					fill="#000000CC"
					>{{ question }}</text
				>
				<text
					opacity="1.0"
					x="100"
					y="-330"
					class="txt"
					text-anchor="start"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="96"
					fill="#000000CC"
					>{{ answer }}</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="condition == 'RP'">
				<transition name="quickfade">
					<g v-if="num_rp_trials < 2">
						<rect
							stroke="#000000ff"
							stroke-width="3"
							fill="#FFFFFFCC"
							x="-240"
							y="-280"
							width="484"
							height="100"
							rx="5"
						/>
						<text
							x="0"
							y="-240"
							class="txt"
							text-anchor="middle"
							dominant-baseline="middle"
							font-family="sans-serif"
							font-size="20"
							fill="#000000FF"
							>Räkna ut svaret och</text
						>
						<text
							x="0"
							y="-210"
							class="txt"
							text-anchor="middle"
							dominant-baseline="middle"
							font-family="sans-serif"
							font-size="20"
							fill="#000000FF"
							>skriv med knapparna nedan</text
						>
					</g>
				</transition>
				<g
					v-for="(v, idx) in button_pos"
					:key="idx"
					v-on:click="btn_click(idx)"
					v-on:mouseover="btn_mouse_over(idx)"
					v-on:mouseleave="btn_mouse_leave(idx)"
				>
					<rect
						stroke="#00000020"
						stroke-width="5"
						:fill="btn_colour(idx)"
						:x="v.x * 110 - 47"
						:y="v.y * 110 - 47 + 50"
						width="95"
						height="95"
						rx="15"
					/>
					<text
						:x="v.x * 110"
						:y="v.y * 110 + 56"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="50"
						fill="#FFFFFF"
						>{{ idx }}</text
					>
				</g>
				<g v-on:click="click_ok()">
					<rect
						stroke="#00000020"
						stroke-width="5"
						:fill="btn_ok_colour()"
						x="190"
						y="-80"
						width="120"
						height="120"
						rx="15"
					/>
					<text
						x="250"
						y="-17"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="40"
						fill="#FFFFFF"
						>OK</text
					>
				</g>
				<g v-on:click="click_erase()">
					<rect
						stroke="#00000020"
						stroke-width="5"
						:fill="btn_erase_colour()"
						x="190"
						y="60"
						width="120"
						height="120"
						rx="15"
					/>
					<text
						x="247"
						y="123"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="40"
						fill="#FFFFFF"
						>⌫</text
					>
				</g>
				<g v-if="item_feedback">
					<g
						v-if="feedback_response_correct"
						opacity="1.0"
						transform="scale(4.4, 4.4) translate(-90, -75)"
					>
						<path
							d="M 99 74 C 138 75 138 75 109 96 C 119 130 119 130 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74 C 90 40 90 40 102 74"
							stroke="#00000022"
							stroke-width="8"
							fill="#FFCC66"
						/>
						<path
							d="M 78 74 C 90 40 90 40 102 74 C 138 75 138 75 109 96"
							stroke="none"
							fill="#FFFFAA2C"
						/>
						<path
							d="M 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74"
							stroke="none"
							fill="#0000000C"
						/>
					</g>
					<circle v-else cx="0" cy="55" r="120" fill="#FF333388" />
					<text
						opacity="1.0"
						x="0"
						y="90"
						class="txt"
						text-anchor="middle"
						dominant-baseline="auto"
						font-family="sans-serif"
						font-size="96"
						fill="#000000DD"
						>{{ this.current_x * this.current_y }}</text
					>
				</g>
				<text
					x="600"
					y="270"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="30"
					fill="#000000FF"
					>2 - 4½ minuter,</text
				>
				<text
					x="600"
					y="305"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="30"
					fill="#000000FF"
					>retrieval practice</text
				>
			</g>
		</transition>

		<transition name="quickfade">
			<g v-if="condition == 'Mat'">
				<transition name="quickfade">
					<g v-if="num_mat_trials < 2">
						<rect
							stroke="#000000ff"
							stroke-width="3"
							fill="#FFFFFFCC"
							x="-700"
							y="0"
							width="300"
							height="100"
							rx="5"
						/>
						<text
							x="-550"
							y="40"
							class="txt"
							text-anchor="middle"
							dominant-baseline="middle"
							font-family="sans-serif"
							font-size="20"
							fill="#000000FF"
							>Klicka på rätt</text
						>
						<text
							x="-550"
							y="65"
							class="txt"
							text-anchor="middle"
							dominant-baseline="middle"
							font-family="sans-serif"
							font-size="20"
							fill="#000000FF"
							>ruta i tabellen</text
						>
						<text
							x="-445"
							y="57"
							class="txt"
							text-anchor="middle"
							dominant-baseline="middle"
							font-family="sans-serif"
							font-size="40"
							fill="#000000FF"
							>🠆</text
						>
					</g>
				</transition>

				<g v-for="(vy, iy) in 12" :key="iy">
					<g
						v-for="(vx, ix) in 12"
						:key="ix"
						v-on:mousedown="mat_mousedown(vx, vy)"
						v-on:mouseup="mat_mouseup()"
						v-on:touchstart="mat_touchstart(vx, vy, $event)"
						v-on:touchend="mat_touchend($event)"
						v-on:mouseover="mat_mouse_over(vx, vy)"
						v-on:mouseleave="mat_mouse_leave(vx, vy)"
					>
						<rect
							stroke="#00000020"
							stroke-width="3"
							:fill="mat_colour(vx, vy)"
							:x="ix * 64 - 360 - 20"
							:y="iy * 60 - 260 - 20"
							width="60"
							height="55"
							rx="5"
						/>
						<text
							:x="ix * 64 - 351"
							:y="iy * 60 - 250"
							class="txt"
							text-anchor="middle"
							dominant-baseline="middle"
							font-family="sans-serif"
							font-size="32"
							fill="#FFFFFF"
							>{{ vx * vy }}</text
						>
						<!-- mat_colour(vx, vy) -->
						<!-- {{ vx * vy }} -->
					</g>
				</g>
				<g v-if="item_feedback">
					<g
						v-if="feedback_response_correct"
						opacity="1.0"
						:transform="
							'scale(1.2, 1.2) translate(' +
							String(-433 + this.current_x * 53) +
							',' +
							String(-350 + this.current_y * 50) +
							')'
						"
					>
						<path
							d="M 99 74 C 138 75 138 75 109 96 C 119 130 119 130 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74 C 90 40 90 40 102 74"
							stroke="#00000022"
							stroke-width="8"
							fill="#FFCC66"
						/>
						<path
							d="M 78 74 C 90 40 90 40 102 74 C 138 75 138 75 109 96"
							stroke="none"
							fill="#FFFFAA2C"
						/>
						<path
							d="M 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74"
							stroke="none"
							fill="#0000000C"
						/>
					</g>
					<circle
						v-else
						:cx="-414 + this.current_x * 64"
						:cy="-312 + this.current_y * 60"
						r="35"
						fill="#FF333388"
					/>
					<text
						:x="-415 + this.current_x * 64"
						:y="-310 + this.current_y * 60"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="32"
						fill="#000000"
						>{{ this.current_x * this.current_y }}</text
					>
					<g
						v-if="feedback_response_correct"
						opacity="1.0"
						:transform="
							'scale(1.2, 1.2) translate(' +
							String(-433 + this.current_y * 53) +
							',' +
							String(-350 + this.current_x * 50) +
							')'
						"
					>
						<path
							d="M 99 74 C 138 75 138 75 109 96 C 119 130 119 130 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74 C 90 40 90 40 102 74"
							stroke="#00000022"
							stroke-width="8"
							fill="#FFCC66"
						/>
						<path
							d="M 78 74 C 90 40 90 40 102 74 C 138 75 138 75 109 96"
							stroke="none"
							fill="#FFFFAA2C"
						/>
						<path
							d="M 90 110 C 61 130 61 130 71 96 C 42 75 42 75 78 74"
							stroke="none"
							fill="#0000000C"
						/>
					</g>
					<circle
						v-else
						:cx="-414 + this.current_y * 64"
						:cy="-312 + this.current_x * 60"
						r="35"
						fill="#FF333388"
					/>
					<text
						:x="-415 + this.current_y * 64"
						:y="-310 + this.current_x * 60"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="32"
						fill="#000000"
						>{{ this.current_x * this.current_y }}</text
					>
				</g>
				<text
					x="600"
					y="-105"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="30"
					fill="#000000FF"
					>2 - 4½ minuter,</text
				>
				<text
					x="600"
					y="-70"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="30"
					fill="#000000FF"
					>memorera med</text
				>
				<text
					x="600"
					y="-40"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="30"
					fill="#000000FF"
					>vägledning av</text
				>
				<text
					x="600"
					y="-10"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="30"
					fill="#000000FF"
					>tabellen</text
				>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import countDown from "@/v/countDown2.vue";
import * as cards from "./cards.js";

var session_results = {
	rp_rts: [],
	rp_corr_rts: [],
	rp_err_rts: [],
	//
	mat_rts: [],
	mat_corr_rts: [],
	mat_err_rts: [],
};

var mat_cross_timeout = 0;
var ok_btn_timeout = 0;

export default {
	name: "choke_practice_multiplication_demo",
	//props: ['stim_param'],
	//mixins: [lang],
	computed: {
		text() {
			return 0;
			//return this.getLocalisedText();
		},
		button_pos() {
			return [
				{ x: 0, y: 2 },
				{ x: -1, y: -1 },
				{ x: 0, y: -1 },
				{ x: 1, y: -1 },
				{ x: -1, y: 0 },
				{ x: 0, y: 0 },
				{ x: 1, y: 0 },
				{ x: -1, y: 1 },
				{ x: 0, y: 1 },
				{ x: 1, y: 1 },
			];
		},
	},
	data: () => {
		return {
			start_time: 0,
			show_buttons: true,
			input_disabled: false,
			items: [],
			condition: "",
			btn_ok_down: false,
			btn_erase_down: false,
			btn_hover: -1,
			question: "",
			answer: "",
			mat_hover: [-1, -1],
			show_mat_answer: false,
			current_x: 1,
			current_y: 1,
			rp_deck: [],
			mat_deck: [],
			rp_set: "",
			mat_set: "",
			item_feedback: false,
			//result_text1: "",
			//result_text2: "",
			feedback_response_correct: false,
			pract_length: 0,
			session_score: 0,
			crossover: 0,
			beforeUnloadHandler: null,
			initial_session: 0,
			num_mat_trials: 0,
			num_rp_trials: 0,
		};
	},
	methods: {
		btn_mouse_over(idx) {
			if (this.input_disabled) return;
			this.btn_hover = idx;
		},
		btn_mouse_leave(idx) {
			if (this.btn_hover == idx) this.btn_hover = -1;
		},
		btn_click(idx) {
			if (this.input_disabled) return;
			if (this.answer.length == 0 && idx == 0) return;

			if (
				this.answer.length < 2 ||
				(this.answer.length == 2 && this.answer[0] == "1")
			) {
				this.answer = this.answer + idx;
				//this.$set(this.btn_state, idx, true);
				//setTimeout(() => {
				//	this.$set(this.btn_state, idx, false);
				//}, 100);
			}
		},
		click_ok() {
			if (this.input_disabled) return;
			this.input_disabled = true;

			if (!this.btn_ok_down) {
				this.btn_ok_down = true;
				clearTimeout(ok_btn_timeout);
				ok_btn_timeout = setTimeout(() => {
					this.btn_ok_down = false;
				}, 100);
				var corr = this.answer == this.current_x * this.current_y;
				this.feedback_response_correct = corr;

				this.item_feedback = true;
				setTimeout(() => {
					this.item_feedback = false;
					this.log_rp_response();
				}, 2000);
			}
		},
		click_erase() {
			if (this.input_disabled) return;

			if (!this.btn_erase_down) {
				if (this.answer.length > 0) {
					this.answer = this.answer.substring(
						0,
						this.answer.length - 1
					);

					this.btn_erase_down = true;
					setTimeout(() => {
						this.btn_erase_down = false;
					}, 100);
				}
			}
		},
		new_rp_item() {
			if (this.num_rp_trials++ > 2) {
				this.$refs.countDown2.stop();
				this.end_test();
				return;
			}

			if (this.condition != "RP") return;
			this.item_feedback = false;
			//console.log("new_rp_item() ---");
			this.item_start_time = Date.now();
			var item = cards.get_current_item(this.rp_deck, this.rp_set);
			this.current_x = item.x;
			this.current_y = item.y;
			this.question = item.y + "·" + item.x + " =";
			this.answer = "";
			this.show_mat_answer = false;
			this.input_disabled = false;
			clearTimeout(mat_cross_timeout);
			clearTimeout(ok_btn_timeout);
		},
		new_mat_item() {
			if (this.num_mat_trials++ > 2) {
				this.$refs.countDown1.stop();
				clearTimeout(mat_cross_timeout);
				this.$refs.countDown2.play(
					() => {
						this.end_test();
					},
					0,
					this.pract_length,
					600,
					280
				);
				this.start_rp_set();
				return;
			}

			if (this.condition != "Mat") return;
			this.item_feedback = false;
			this.item_start_time = Date.now();
			var item = cards.get_current_item(this.mat_deck, this.mat_set);
			this.current_x = item.x;
			this.current_y = item.y;
			this.question = item.y + "·" + item.x + " =";

			this.answer = item.x * item.y;
			this.show_mat_answer = false;
			clearTimeout(mat_cross_timeout);
			mat_cross_timeout = setTimeout(() => {
				if (this.condition == "Mat") {
					this.show_mat_answer = true;
				}
			}, 10000);
			this.input_disabled = false;
			clearTimeout(ok_btn_timeout);
		},
		mat_colour(vx, vy) {
			if (this.mat_hover[0] == vx && this.mat_hover[1] == vy) {
				return "#FF777788";
			}
			if (this.show_mat_answer == false) {
				if (vx == 1 || vy == 1) {
					return "#77777722";
				} else {
					return "#777777FF";
				}
			} else {
				if (vx == 1 || vy == 1) {
					if (
						vx == this.current_x ||
						vy == this.current_y ||
						vx == this.current_y ||
						vy == this.current_x
					) {
						return "#00005522";
					} else {
						return "#77777722";
					}
				} else {
					if (
						(vx == this.current_x && vy == this.current_y) ||
						(vx == this.current_y && vy == this.current_x)
					) {
						return "#88DD88FF";
					} else if (
						(vx == this.current_x && vy < this.current_y) ||
						(vx == this.current_y && vy < this.current_x) ||
						(vx < this.current_x && vy == this.current_y) ||
						(vx < this.current_y && vy == this.current_x)
					) {
						return "#777788FF";
					} else {
						return "#777777FF";
					}
				}
			}
		},
		mat_mouse_over(vx, vy) {
			if (vx > 1 && vy > 1) this.mat_hover = [vx, vy];
		},
		mat_mouse_leave(vx, vy) {
			if (this.mat_hover[0] == vx && this.mat_hover[1] == vy) {
				this.mat_hover = [-1, -1];
			}
		},
		mat_mousedown(vx, vy) {
			if (this.input_disabled) return;
			this.log_mat_response(vx, vy);
		},
		mat_mouseup() {},
		mat_touchstart(vx, vy, event) {
			if (event) {
				event.preventDefault();
			}
			if (this.input_disabled) return;
			this.log_mat_response(vx, vy);
		},
		mat_touchend(event) {
			if (event) {
				event.preventDefault();
			}
		},
		log_mat_response(vx, vy) {
			this.input_disabled = true;

			//var rt = ((Date.now() - this.item_start_time) / 1000.0).toFixed(3);
			var rt = (Date.now() - this.item_start_time) / 1000.0;
			var corr = vx * vy == this.current_x * this.current_y;
			this.feedback_response_correct = corr;
			this.item_feedback = true;

			session_results.mat_rts.push(rt);
			if (corr) session_results.mat_corr_rts.push(rt);
			else session_results.mat_err_rts.push(rt);
			cards.update(this.mat_deck, corr, rt);

			setTimeout(() => {
				this.item_feedback = false;
				this.new_mat_item();
			}, 2000);
		},
		log_rp_response() {
			//var rt = ((Date.now() - this.item_start_time) / 1000.0).toFixed(3);
			var rt = (Date.now() - this.item_start_time) / 1000.0;
			var corr = this.answer == this.current_x * this.current_y;
			/*console.log(
				"log rp: " + this.answer + ", corr: " + corr + ", rt: " + rt
			);*/

			session_results.rp_rts.push(rt);
			if (corr) session_results.rp_corr_rts.push(rt);
			else session_results.rp_err_rts.push(rt);
			cards.update(this.rp_deck, corr, rt);

			this.new_rp_item();
		},
		btn_ok_colour() {
			if (this.answer.length == 0) return "#66BB6622";
			return this.btn_ok_down ? "#99EE99FF" : "#66BB66FF";
		},
		btn_erase_colour() {
			if (this.answer.length == 0) return "#BB666622";
			return this.btn_erase_down ? "#EE9999FF" : "#BB6666FF";
		},
		btn_colour(idx) {
			if (
				this.answer.length == 3 ||
				(this.answer.length == 2 && this.answer[0] != "1")
			)
				return "#77777722";

			//if (this.btn_down == idx) return "#ccccff99";
			//else
			if (this.btn_hover == idx) return "#FF777788";
			else return "#77777788";
		},
		end_test() {
			clearTimeout(mat_cross_timeout);
			clearTimeout(ok_btn_timeout);
			this.$refs.countDown1.stop();
			this.$refs.countDown2.stop();

			this.condition = "Result";
			this.question = "";
			this.answer = "";
			this.item_feedback = false;
			this.input_disabled = true;
			this.show_buttons = false;

			setTimeout(() => {
				var history = [2, 1, 3, 4, 6];
				store.commit("setSessionScores", history);
				store.commit("setTaskMode", "sessionScore");
			}, 1000);
		},
		setup() {
			store.commit("setTaskMode", "playing");
			//store.commit('setTaskMode', 'readyImmediateInstruct');
			//store.commit("setTaskMode", "ready_no_exit");
			store.commit("taskPhase", 0);
			this.$parent.show_buttons = false;
		},
		start_rp_set() {
			this.condition = "RP";
			this.new_rp_item();
		},
		start_mat_set() {
			this.condition = "Mat";
			this.new_mat_item();
		},
	},
	components: {
		countDown,
	},
	mounted() {
		// Session 0: kog
		// Session 1: test multiplication
		// Session 2+: practice multiplication
		// Session mid: test multiplication
		// Session mid+: practice multiplication
		// Session post: test multiplication

		//console.log("session: " + session);

		// TODO: message
		//store.commit("setTaskMode", "clickToExit");
		//store.commit("setTaskMode", "sessionDone");
		//	return;

		this.rp_set = "A";
		this.mat_set = "B";
		this.crossover = 0;

		const pract_length = 360;
		this.pract_length = pract_length;

		// init rp deck
		this.rp_deck = cards.new_deck();

		// init mat deck
		this.mat_deck = cards.new_deck();

		this.setup();

		//if (first_cond == "RP") this.start_rp_set();
		//else this.start_mat_set();
		this.start_mat_set();

		this.start_time = Date.now();

		this.$refs.countDown1.play(
			() => {
				this.start_rp_set();
			},
			0,
			pract_length,
			600,
			-65
		);
		this.$refs.countDown2.play(
			() => {
				this.end_test();
			},
			this.pract_length,
			this.pract_length,
			600,
			280
		);
	},
};
</script>
