<template>
	<path
		v-show="playing"
		:id="unique_id"
		opacity="0"
		fill="none"
		stroke="#FFFFFF"
		stroke-width="100"
	/>
</template>

<script>
import gsap from "gsap";

export default {
	name: "count-down",
	data: function () {
		return {
			playing: false,
			timeline: null,
		};
	},
	props: {
		name: {
			type: String,
			required: true,
		},
	},
	computed: {
		unique_id() {
			return `arc-${this.name}`;
		},
	},
	methods: {
		stop() {
			this.playing = false;
			this.timeline.pause();
		},
		play(whenDone, delay = 0, duration = 1.0, x = 0, y = 0) {
			//console.log("starting timer, duration: " + duration);
			this.playing = true;
			const arc = document.getElementById(this.unique_id);

			arc.setAttribute("d", describeArc(x, y, 100, 0.001, 360));
			arc.setAttribute("opacity", 0.5);

			var dummy = { prop: 0, opacity: 1 };
			//var dummy = { prop: 0 };

			//var t1
			this.timeline = gsap.timeline();

			// t1.to(dummy, {
			// 	duration: delay,
			// 	//delay: 0.25,
			// 	//delay: delay,
			// 	prop: 0.5,
			// 	ease: "none",
			// 	onUpdate: () => {
			// 		arc.setAttribute(
			// 			"d",
			// 			//describeArc(x, y, 100, dummy.prop * 360, 360)
			// 			describeArc(x, y, 100, 30, 360)
			// 		);
			// 	},
			// });

			this.timeline.to(dummy, {
				//duration: 3,
				delay: delay,
				duration: duration,
				//opacity: 1,
				prop: 1,
				//delay: 10,
				//delay: 3,
				//delay: 0.1,
				ease: "none",
				onUpdate: () => {
					arc.setAttribute(
						"d",
						describeArc(x, y, 100, dummy.prop * 360, 360)
					);

					/*arc.setAttribute(
						"opacity",
						Math.min(0.8, 1.4 - dummy.prop)
					);*/
				},
				onComplete: () => {
					this.playing = false;
					arc.setAttribute("opacity", 0);
					whenDone();
				},
			});
		},
	},
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians),
	};
}

function describeArc(x, y, radius, startAngle, endAngle) {
	var start = polarToCartesian(x, y, radius, endAngle);
	var end = polarToCartesian(x, y, radius, startAngle);

	var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	var d = [
		"M",
		start.x,
		start.y,
		"A",
		radius,
		radius,
		0,
		largeArcFlag,
		0,
		end.x,
		end.y,
	].join(" ");

	return d;
}
</script>
