<template>
	<g>
		<transition name="fade">
			<foreignObject
				v-if="true"
				x="-600"
				y="-495"
				width="1200"
				height="250"
			>
				<div class="title">{{ this.text.welcome }}</div>
			</foreignObject>
		</transition>
		<transition name="fade">
			<foreignObject
				v-if="true"
				x="-600"
				y="-300"
				width="1200"
				height="700"
			>
				<div class="inp">
					<input
						type="text"
						id="loginName"
						name="loginName"
						class="field"
						:placeholder="this.text.your_username"
						spellcheck="false"
						autocomplete="off"
						autocorrect="off"
						enterkeyhint="send"
						mozactionhint="send"
						autofocus="true"
						required="true"
					/>
				</div>
				<div class="inp">
					<input
						type="password"
						id="loginPwd"
						name="loginPwd"
						class="field"
						:placeholder="this.text.your_password"
						spellcheck="false"
						autocomplete="off"
						autocorrect="off"
						enterkeyhint="send"
						mozactionhint="send"
						required="true"
					/>
				</div>
				<div class="inp">
					<div class="link" v-on:click="doLogin">
						{{ this.text.login }}
					</div>
				</div>
			</foreignObject>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import persist from "@/persist.js";
import lang from "@/v/login/lang.js";

function openFullscreen() {
	const elem = document.documentElement; // This selects the entire document

	if (elem.requestFullscreen) {
		elem.requestFullscreen();
	} else if (elem.mozRequestFullScreen) {
		// Firefox
		elem.mozRequestFullScreen();
	} else if (elem.webkitRequestFullscreen) {
		// Chrome, Safari, and Opera
		elem.webkitRequestFullscreen();
	} else if (elem.msRequestFullscreen) {
		// IE/Edge
		elem.msRequestFullscreen();
	}
}

function closeFullscreen() {
	if (!document.fullscreenElement) return;

	if (document.exitFullscreen) {
		document.exitFullscreen();
	} else if (document.mozCancelFullScreen) {
		// Firefox
		document.mozCancelFullScreen();
	} else if (document.webkitExitFullscreen) {
		// Chrome, Safari, and Opera
		document.webkitExitFullscreen();
	} else if (document.msExitFullscreen) {
		// IE/Edge
		document.msExitFullscreen();
	}
}

export default {
	name: "login",
	components: {},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	methods: {
		enterKeyPushed: function (e) {
			if (e.key === "Enter") {
				this.doLogin();
			}
		},
		doLogin: function () {
			const name_input = document.getElementById("loginName");
			const pwd_input = document.getElementById("loginPwd");
			const name = name_input.value.toLowerCase();
			const pwd = pwd_input.value.toLowerCase();
			if (name != "" && pwd != "") {
				openFullscreen();
				persist
					.login(name, pwd)
					.then((ready) => {
						if (ready) {
							this.$store.commit("hideError");
							store.dispatch("gotoModule", "home");
						} else {
							pwd_input.focus();
							pwd_input.setSelectionRange(
								0,
								pwd_input.value.length
							);
							this.$store.commit(
								"showError",
								this.text.wrong_password
							);
						}
					})
					.catch((e) => {
						/* eslint-disable no-console */
						console.error("login error " + e);
						/* eslint-enable no-console */
					});
			} else if (name == "") {
				name_input.focus();
			} else if (pwd == "") {
				pwd_input.focus();
			}
		},
	},
	mounted() {
		closeFullscreen();
		this.$store.commit("reset_forms");
		const fields = document.getElementsByClassName("field");
		fields[0].addEventListener("keyup", this.enterKeyPushed.bind(this)); // username
		fields[1].addEventListener("keyup", this.enterKeyPushed.bind(this)); // password
		const current_name = localStorage.getItem("current_name");
		if (current_name) {
			fields[0].value = current_name;
			fields[0].focus();
			fields[0].setSelectionRange(0, fields[0].value.length);
		} else {
			fields[0].focus();
		}
	},
};
</script>

<style lang="stylus">
.inp {
	cursor: default;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #00000011;
	border-radius: 35px;
	text-align: center;
}

.field {
	cursor: text;
	font-family: serif;
	font-size: 80px;
	color: #000000;
	text-align: center;
	text-text-justify: auto;
	letter-spacing: 0.1em;
	display: inline-block;
	width: 1088px;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	padding-left: 40px;
	padding-right: 40px;
	background-color: #FFFFFF55;
	border-radius: 25px;
	border: 4px solid white;
}
</style>
