<template>
	<g>
		<transition name="fade">
			<foreignObject
				v-if="true"
				x="-600"
				y="-495"
				width="1200"
				height="599"
			>
				<div class="txtbox" v-html="this.text.menu_help" />
			</foreignObject>
		</transition>

		<transition name="fade">
			<g
				:opacity="this.shifting_predictable_opacity"
				transform="translate(-300, -140)"
				v-on:click="this.click_shifting_predictable"
			>
				<shifting_predictable_icon />
			</g>
		</transition>
		<transition name="fade">
			<g
				:opacity="this.shifting_unpredictable_opacity"
				transform="translate(-300, 160)"
				v-on:click="this.click_shifting_unpredictable"
			>
				<shifting_unpredictable_icon />
			</g>
		</transition>
		<transition name="fade">
			<g
				:opacity="this.episodic_memory_relational_opacity"
				transform="translate(0, -140)"
				v-on:click="this.click_episodic_memory_relational"
			>
				<episodic_memory_relational_icon />
			</g>
		</transition>
		<transition name="fade">
			<g
				:opacity="this.updating_letters_opacity"
				transform="translate(300, -140)"
				v-on:click="this.click_updating_letters"
			>
				<lettersUpdatingIcon />
			</g>
		</transition>

		<transition name="fade">
			<g
				:opacity="this.wm_maintenance_opacity"
				transform="translate(-0, 160)"
				v-on:click="this.click_wm_maintenance"
			>
				<wm_maintenance_icon />
			</g>
		</transition>
		<transition name="fade">
			<g
				:opacity="this.keep_track_opacity"
				transform="translate(300, 160)"
				v-on:click="this.click_keep_track"
			>
				<keepTrackIcon />
			</g>
		</transition>

		<transition name="fade">
			<g transform="translate(670, -490)">
				<logoutButton />
			</g>
		</transition>

		<session_timeline :sessions="36" :current_session="this.session" />
		<text
			x="-400"
			y="483"
			class="txt"
			text-anchor="end"
			dominant-baseline="middle"
			font-family="sans-serif"
			font-size="30"
			fill="#FFFFFF"
			>{{ this.text.currentSession(session + 1) }}</text
		>
	</g>
</template>

<script>
import { store } from "@/store.js";
import lang from "@/v/reco/menu/lang.js";

import shifting_predictable_icon from "@/v/taskMenu/icons/shifting_predictable_icon.vue";
import shifting_unpredictable_icon from "@/v/taskMenu/icons/shifting_unpredictable_icon.vue";
import episodic_memory_relational_icon from "@/v/taskMenu/icons/episodic_memory_relational_icon.vue";
import lettersUpdatingIcon from "@/v/taskMenu/icons/lettersUpdatingIcon.vue";
import wm_maintenance_icon from "@/v/taskMenu/icons/wm_maintenance_icon.vue";
import keepTrackIcon from "@/v/taskMenu/icons/keepTrackIcon.vue";

import logoutButton from "@/v/taskMenu/logoutButton.vue";
import session_timeline from "@/v/session_timeline.vue";
import persist from "@/persist.js";

export default {
	name: "menu_reco",
	components: {
		shifting_predictable_icon,
		shifting_unpredictable_icon,
		episodic_memory_relational_icon,
		lettersUpdatingIcon,
		wm_maintenance_icon,
		keepTrackIcon,
		session_timeline,
		logoutButton,
	},
	mixins: [lang],
	computed: {
		text() {
			return this.getLocalisedText();
		},
	},
	data: () => {
		return {
			show_shifting_predictable: false,
			show_shifting_unpredictable: false,
			show_episodic_memory_relational: false,
			show_updating_letters: false,
			show_wm_maintenance: false,
			show_keep_track: false,

			shifting_predictable_opacity: 0,
			shifting_unpredictable_opacity: 0,
			episodic_memory_relational_opacity: 0,
			updating_letters_opacity: 0,
			wm_maintenance_opacity: 0,
			keep_track_opacity: 0,

			session: 0,
		};
	},
	methods: {
		click_shifting_predictable() {
			if (this.show_shifting_predictable)
				store.dispatch("gotoModule", "reco_shifting_predictable");
		},
		click_shifting_unpredictable() {
			if (this.show_shifting_unpredictable)
				store.dispatch("gotoModule", "reco_shifting_unpredictable");
		},
		click_episodic_memory_relational() {
			if (this.show_episodic_memory_relational)
				store.dispatch("gotoModule", "reco_episodic_memory_relational");
		},
		click_updating_letters() {
			if (this.show_updating_letters)
				store.dispatch("gotoModule", "reco_updating_letters");
		},
		click_wm_maintenance() {
			if (this.show_wm_maintenance)
				store.dispatch("gotoModule", "reco_wm_maintenance");
		},
		click_keep_track() {
			if (this.show_keep_track)
				store.dispatch("gotoModule", "reco_keep_track");
		},
	},
	mounted() {
		//console.log('LOGIN:');
		//console.log(this.$store.state.login_progress);
		//console.log('NOW:');
		//console.log(this.$store.state.progress);

		const login_shifting_num =
			this.$store.state.login_progress.shifting_num || 0;
		const login_shifting_up_num =
			this.$store.state.login_progress.shifting_up_num || 0;
		const login_emr_num = this.$store.state.login_progress.emr_num || 0;
		const login_updating_letters_num =
			this.$store.state.login_progress.updating_letters_num || 0;
		const login_wm_maintenance_num =
			this.$store.state.login_progress.wm_maintenance_num || 0;
		const login_keep_track_num =
			this.$store.state.login_progress.keep_track_num || 0;

		//---

		const current_shifting_num =
			this.$store.state.progress.shifting_num || 0;
		const current_shifting_up_num =
			this.$store.state.progress.shifting_up_num || 0;
		const current_emr_num = this.$store.state.progress.emr_num || 0;
		const current_updating_letters_num =
			this.$store.state.progress.updating_letters_num || 0;
		const current_wm_maintenance_num =
			this.$store.state.progress.wm_maintenance_num || 0;
		const current_keep_track_num =
			this.$store.state.progress.keep_track_num || 0;

		//---
		const session = Math.min(
			login_shifting_num + login_shifting_up_num,
			login_emr_num,
			login_updating_letters_num,
			login_wm_maintenance_num,
			login_keep_track_num
		);

		/*console.log("------------------------------");
		console.log('login_shifting_num:                ' + login_shifting_num);
		console.log('login_shifting_up_num:             ' + login_shifting_up_num);
		console.log('login_emr_num:                     ' + login_emr_num);
		console.log('login_updating_letters_num:        ' + login_updating_letters_num);
		console.log('login_wm_maintenance_num:          ' + login_wm_maintenance_num);
		console.log('login_keep_track_num:              ' + login_keep_track_num);
		console.log("------------------------------");
		*/

		// Skifta: varannan gång predictable (siffror), varannan unpredictable (bokstäver)
		var current_shifting_any_num =
			current_shifting_num + current_shifting_up_num;
		var show_shifting_any = current_shifting_any_num <= session;
		//console.log('>> current_shifting_any_num:       ' + current_shifting_any_num);
		//console.log('>> show_shifting_any:              ' + show_shifting_any);

		this.show_shifting_predictable =
			show_shifting_any && current_shifting_any_num % 2 == 0;
		this.show_shifting_unpredictable =
			show_shifting_any && current_shifting_any_num % 2 == 1;
		this.show_episodic_memory_relational = current_emr_num <= session;
		this.show_updating_letters = current_updating_letters_num <= session;
		this.show_wm_maintenance = current_wm_maintenance_num <= session;
		this.show_keep_track = current_keep_track_num <= session;

		if (session % 2 == 0) {
			//if (current_shifting_any_num % 2 == 0) {
			this.shifting_predictable_opacity = show_shifting_any ? 1.0 : 0.1;
			this.shifting_unpredictable_opacity = 0.1;
			//console.log(">>> shifting_predictable_opacity: " + this.shifting_predictable_opacity);
		} else {
			this.shifting_predictable_opacity = 0.1;
			this.shifting_unpredictable_opacity = show_shifting_any ? 1.0 : 0.1;
			//console.log(">>> shifting_unpredictable_opacity: " + this.shifting_unpredictable_opacity);
		}
		this.episodic_memory_relational_opacity = this
			.show_episodic_memory_relational
			? 1.0
			: 0.1;
		this.updating_letters_opacity = this.show_updating_letters ? 1.0 : 0.1;
		this.wm_maintenance_opacity = this.show_wm_maintenance ? 1.0 : 0.1;
		this.keep_track_opacity = this.show_keep_track ? 1.0 : 0.1;

		/* eslint-disable no-console */
		/*console.log("MENU: ");
		console.log(" show_shifting_any:               " + show_shifting_any);
		console.log(" show_shifting_predictable:       " + this.show_shifting_predictable);
		console.log(" show_shifting_unpredictable:     " + this.show_shifting_unpredictable);
		console.log(" show_episodic_memory_relational: " + this.show_episodic_memory_relational);
		console.log(" show_updating_letters:           " + this.show_updating_letters);
		console.log(" show_wm_maintenance:             " + this.show_wm_maintenance);
		console.log(" show_keep_track:                 " + this.show_keep_track);
		*/

		/*console.log("A:");
		console.log('current_emr_num: ' + current_emr_num);
		console.log('current_wm_maintenance_num: ' + current_wm_maintenance_num);
		*/

		this.session = session;

		/*console.log("------------------");
		console.log("     session:     " + session);
		console.log("-");
		console.log("      a_sessions: " + a_sessions);
		console.log("      b_sessions: " + b_sessions);
		console.log("display_sessions: " + display_session);
		console.log("-----");

		console.log(" health_done:         " + this.$store.state.health_done);
		console.log(" motivation_done:     " + this.$store.state.motivation_done);
		console.log(" mental_effort_done:  " + this.$store.state.mental_effort_done);
		console.log(" mental_fatigue_done: " + this.$store.state.mental_fatigue_done);
		console.log(" concentration_done:  " + this.$store.state.concentration_done);*/
		if (this.$store.state.health_done == false) {
			//console.log("goto form_health");
			store.dispatch("gotoModule", "form_health");
		} else if (this.$store.state.motivation_done == false) {
			//console.log("goto form_motivation");
			store.dispatch("gotoModule", "form_motivation");
		} else if (this.$store.state.concentration_done) {
			//console.log("goto login");
			persist.logout();
			store.dispatch("gotoModule", "login");
		} else if (
			this.show_shifting_predictable ||
			this.show_shifting_unpredictable ||
			this.show_episodic_memory_relational ||
			this.show_updating_letters ||
			this.show_wm_maintenance ||
			this.show_keep_track
		) {
			//console.log("show menu!");
		} else if (this.$store.state.mental_effort_done == false) {
			//console.log("goto form_mental_effort");
			store.dispatch("gotoModule", "form_mental_effort");
		} else if (this.$store.state.concentration_done == false) {
			/*else if (this.$store.state.mental_fatigue_done == false) {
			//console.log("goto mental_fatigue");
			store.dispatch("gotoModule", 'mental_fatigue');
		}*/
			//console.log("goto form_concentration");
			store.dispatch("gotoModule", "form_concentration");
		} else {
			//console.log("show menu I guess!");
		}
		/* eslint-enable no-console */
	},
};
</script>
