<template>
	<g>
		<transition name="quickfade">
			<g v-if="trial <= 121">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#FFFFFF88"
					x="-300"
					y="-420"
					width="600"
					height="110"
					rx="15"
				/>
				<text
					opacity="1.0"
					x="0"
					y="-330"
					class="txt"
					text-anchor="middle"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="96"
					fill="#000000CC"
					>{{ this.question }}</text
				>
			</g>
		</transition>
		<g v-if="pilot">
			<g v-if="show_buttons" v-on:click="btn_click(0)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="-210"
					y="-160"
					width="200"
					height="200"
					rx="15"
				/>
				<text
					x="-110"
					y="-55"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ b0_text }}</text
				>
			</g>
			<g v-if="show_buttons" v-on:click="btn_click(1)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="10"
					y="-160"
					width="200"
					height="200"
					rx="15"
				/>
				<text
					x="110"
					y="-55"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ b1_text }}</text
				>
			</g>
			<g v-if="show_buttons" v-on:click="btn_click(2)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="-210"
					y="60"
					width="200"
					height="200"
					rx="15"
				/>
				<text
					x="-110"
					y="165"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ b2_text }}</text
				>
			</g>
			<g v-if="show_buttons" v-on:click="btn_click(3)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="10"
					y="60"
					width="200"
					height="200"
					rx="15"
				/>
				<text
					x="110"
					y="165"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ b3_text }}</text
				>
			</g>
		</g>
		<g v-else>
			<g v-if="show_buttons" v-on:click="btn_click(0)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="-100"
					y="-150"
					width="200"
					height="90"
					rx="15"
				/>
				<text
					x="0"
					y="-100"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ b0_text }}</text
				>
			</g>
			<g v-if="show_buttons" v-on:click="btn_click(1)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="-100"
					y="-40"
					width="200"
					height="90"
					rx="15"
				/>
				<text
					x="0"
					y="10"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ b1_text }}</text
				>
			</g>
			<g v-if="show_buttons" v-on:click="btn_click(2)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="-100"
					y="70"
					width="200"
					height="90"
					rx="15"
				/>
				<text
					x="0"
					y="120"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ b2_text }}</text
				>
			</g>
			<g v-if="show_buttons" v-on:click="btn_click(3)">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="-100"
					y="180"
					width="200"
					height="90"
					rx="15"
				/>
				<text
					x="0"
					y="230"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ b3_text }}</text
				>
			</g>
		</g>
		<transition>
			<g v-if="trial <= 121">
				<rect
					stroke="#000000ff"
					stroke-width="3"
					fill="#00000022"
					x="-240"
					y="400"
					width="484"
					height="20"
					rx="5"
				/>
				<rect
					v-if="trial > 0"
					stroke="#000000ff"
					stroke-width="3"
					fill="#FFFFFFFF"
					x="-242"
					y="400"
					:width="trial * 4"
					height="20"
					rx="5"
				/>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="trial < 1">
				<rect
					stroke="#000000ff"
					stroke-width="3"
					fill="#FFFFFFCC"
					x="-240"
					y="-280"
					width="484"
					height="100"
					rx="5"
				/>
				<text
					x="0"
					y="-230"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="20"
					fill="#000000FF"
					>Försök svara både snabbt och rätt:</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="done">
				<rect
					stroke="#000000ff"
					stroke-width="3"
					fill="#FFFFFFCC"
					x="-240"
					y="-280"
					width="484"
					height="100"
					rx="5"
				/>
				<text
					x="0"
					y="-230"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="30"
					fill="#000000FF"
					>Klart för den här gången!</text
				>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import stat from "@/stat.js";
import persist from "@/persist.js";

function getDayOfYear(date) {
	const start = new Date(date.getFullYear(), 0, 0);
	const diff = date - start;
	const oneDay = 1000 * 60 * 60 * 24;
	const day = Math.floor(diff / oneDay);
	return day;
}

var session_results = {
	rts: [],
	rts_a: [],
	rts_b: [],
	// mean
	sum_rts: 0,
	sum_rts_a: 0,
	sum_rts_b: 0,
	// correct
	num_c: 0,
	num_c_a: 0,
	num_c_b: 0,
	// log
	log: [],
};

// function xround(n) {
// 	return Math.round(n * 100) / 100;
// }

//var mouse_used = false;

//var last_trial_saved = -9;

export default {
	name: "choke_test_multiplication",
	components: {
		//timeline,
		//instructions,
	},
	//props: ['stim_param'],
	//mixins: [lang],
	/*computed: {
		text() {
			return 0;
			//return this.getLocalisedText();
		},
		question() {
			return "7·8";
		},
	},*/
	watch: {
		trial(new_trial) {
			//console.log("new trial: " + new_trial);
			if (new_trial < 0 || new_trial >= this.item_test_order.length) {
				//console.log("new trial: ---");
				this.b0_text = "";
				this.b1_text = "";
				this.b2_text = "";
				this.b3_text = "";
				this.question = "";
			} else {
				//console.log("new trial: ok");
				this.current_item_index = this.item_test_order[new_trial];
				//console.log("new item index: " + this.current_item_index);

				//console.log(this.current_item_index);
				//console.log(this.current_item);
				this.current_item = this.items[this.current_item_index];

				this.question =
					this.current_item.x + "·" + this.current_item.y + " =";

				if (this.pilot) {
					this.b0_text = this.current_item.alt[0];
					this.b1_text = this.current_item.alt[1];
					this.b2_text = this.current_item.alt[2];
					this.b3_text = this.current_item.alt[3];
				} else {
					this.current_item.alt.sort((a, b) => {
						return parseInt(a) - parseInt(b);
					});

					this.b0_text = this.current_item.alt[0];
					this.b1_text = this.current_item.alt[1];
					this.b2_text = this.current_item.alt[2];
					this.b3_text = this.current_item.alt[3];

					//var txt = [];
					//txt.push(this.current_item.alt[0]);
					//txt.push(this.current_item.alt[1]);
					//txt.push(this.current_item.alt[2]);
					//txt.push(this.current_item.alt[3]);
					//txt.sort();
					//txt.sort((a, b) => {
					//	return parseInt(a) - parseInt(b);
					//});

					//this.b0_text = txt[0];
					//this.b1_text = txt[1];
					//this.b2_text = txt[2];
					//this.b3_text = txt[3];
				}

				this.item_start_time = Date.now();
			}
		},
		/*item_visible: function() {
			console.log('ready? ' + this.item_visible);
		}*/
	},
	data: () => {
		return {
			done: false,
			start_time: 0,
			show_buttons: false,
			input_disabled: true,
			trial: -1,
			item_test_order: [],
			items: [],
			current_item: {},
			current_item_index: -1,
			item_start_time: 0,
			question: "",
			b0_text: "",
			b1_text: "",
			b2_text: "",
			b3_text: "",
			//log: [],
			beforeUnloadHandler: null,
			initial_session: 0,
			pilot: false,
		};
	},
	methods: {
		end_test() {
			this.input_disabled = true;
			this.show_buttons = false;
			this.question = "";

			this.saveSessionResults();

			window.removeEventListener(
				"beforeunload",
				this.beforeUnloadHandler
			);

			// Låt anxform avsluta sessionen istället:
			if (this.pilot) {
				this.done = true;
				store.commit("setTaskMode", "sessionDone");
			} else {
				setTimeout(() => {
					store.dispatch("gotoModule", "choke_anxform");
				}, 2000);
			}
		},
		abortAndSave() {
			//console.log("Abort and save...");
			this.saveSessionResults();
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			//console.log("total_time: " + total_time);

			//let rt_mean = session_results.sum_rts / session_results.rts.length;
			//let rt_mean_a =
			//	session_results.sum_rts_a / session_results.rts_a.length;
			//let rt_mean_b =
			//	session_results.sum_rts_b / session_results.rts_b.length;

			//var rt_sd = stat.standardDev(session_results.rts);
			//var rt_sd_a = stat.standardDev(session_results.rts_a);
			//var rt_sd_b = stat.standardDev(session_results.rts_b);

			const [rt_median, rt_mad] =
				stat.mut_array_median_absolute_deviation(session_results.rts);

			const [rt_median_a, rt_mad_a] =
				stat.mut_array_median_absolute_deviation(session_results.rts_a);

			const [rt_median_b, rt_mad_b] =
				stat.mut_array_median_absolute_deviation(session_results.rts_b);

			//persist.set_progress_data("choke_multiple_choice_test", 1);

			const pretest_session = 1;
			// ändra även default i pract_multiplication:
			//const midtest_session = this.$store.state.progress.mid_session || 5; //11;
			//const posttest_session =
			//	this.$store.state.progress.post_session || 8; //16;
			//const grp = this.$store.state.progress.grp || 0;

			if (this.$store.state.progress.mid_session == null) {
				persist.set_progress_data("mid_session", 10);
			}
			const midtest_session = this.$store.state.progress.mid_session;

			if (this.$store.state.progress.post_session == null) {
				persist.set_progress_data("post_session", 19);
			}
			const posttest_session = this.$store.state.progress.post_session;

			if (this.$store.state.progress.grp == null) {
				persist.set_progress_data("grp", 0);
			}
			const grp = this.$store.state.progress.grp;

			let session = this.initial_session;
			if (this.trial > 2) {
				persist.set_progress_data("session", this.initial_session + 1);
			}
			//console.log("saved");

			var session_name = "?";
			if (session == pretest_session) session_name = "pre";
			else if (session == midtest_session) session_name = "mid";
			else if (session == posttest_session) session_name = "post";

			if (!this.pilot) {
				session_results.log.sort((a, b) => {
					if (a.set < b.set) return -1;
					if (a.set > b.set) return 1;
					return a.item - b.item;
				});
			}

			// TODO: jag behöver inte spara detta värde eftersom det är 0, 1, 2, 3, ...
			/*session_results.log.forEach((obj) => {
				delete obj.item;
			});*/

			let c = [];
			let rt = [];
			let ra = []; // response alternative
			//var trial_x = [];
			//var trial_y = [];
			var xy = [];
			let set = [];

			session_results.log.forEach((trial) => {
				c.push(trial.c);
				rt.push(trial.rt);
				ra.push(trial.ra);
				//trial_x.push(trial.x);
				//trial_y.push(trial.y);
				xy.push(trial.x + "·" + trial.y);
				set.push(trial.set);
			});

			const abs_day = getDayOfYear(new Date());
			const first_day = this.$store.state.progress.first_day || abs_day;
			const today = abs_day - first_day;

			persist.log("results", {
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
				//items: session_results.log,
				num_correct: session_results.num_c,
				num_correct_a: session_results.num_c_a,
				num_correct_b: session_results.num_c_b,
				//rt_mean: rt_mean,
				//rt_mean_a: rt_mean_a,
				//rt_mean_b: rt_mean_b,
				//rt_sd: rt_sd,
				//rt_sd_a: rt_sd_a,
				//rt_sd_b: rt_sd_b,
				rt_median: rt_median,
				rt_median_a: rt_median_a,
				rt_median_b: rt_median_b,
				rt_mad: rt_mad,
				rt_mad_a: rt_mad_a,
				rt_mad_b: rt_mad_b,
				s: session,
				session: session_name,
				grp: grp,
				//touch: mouse_used ? 0 : 1,
				// enskilda trials:
				c: c,
				rt: rt,
				ra: ra,
				//x: trial_x,
				//y: trial_y,
				xy: xy,
				//set: set,
				trial: this.trial,
				day: today,
			});
		},
		btn_click(btn) {
			this.log_response(btn);
			this.next_trial();
		},
		// mousedown(btn) {
		// 	this.log_response(btn, true);
		// },
		// mouseup() {
		// 	this.next_trial();
		// },
		// touchstart(btn, event) {
		// 	if (event) {
		// 		event.preventDefault();
		// 	}
		// 	this.log_response(btn, false);
		// },
		// touchend(event) {
		// 	if (event) {
		// 		event.preventDefault();
		// 	}
		// 	this.next_trial();
		// },
		//log_response(btn, mouse_tap) {
		log_response(btn) {
			if (this.input_disabled) return;

			//mouse_used |= mouse_tap;

			if (this.trial > 0) {
				var rt = (Date.now() - this.item_start_time) / 1000.0;
				var answer = this.current_item.alt[btn];
				var correct = 0;
				if (answer == this.current_item.r) {
					correct = 1;
					session_results.num_c += 1;
				}

				if (this.current_item.set == "A") {
					session_results.rts_a.push(rt);
					session_results.sum_rts_a += rt;
					session_results.num_c_a += correct;
				} else if (this.current_item.set == "B") {
					session_results.rts_b.push(rt);
					session_results.sum_rts_b += rt;
					session_results.num_c_b += correct;
				}
				session_results.rts.push(rt);
				session_results.sum_rts += rt;

				var log_value = {
					item: this.current_item_index,
					// TODO: DESSA kommenterade värden behövs för analys av resultaten.
					// Om jag till exempel vill se vilka kombinationer, x*y, som var lätta/svåra:
					x: this.current_item.x,
					y: this.current_item.y,
					c: correct,
					// VILKET VÄRDE VAR KORREKT:
					//cv: this.current_item.r,
					// VILKET SVAR GAVS:
					ra: btn,
					// VILKEN RESPONS VAR KORREKT:
					//rv: this.current_item.alt[btn],
					rt: stat.xround(rt),
					// A eller B eller "-"
					set: this.current_item.set,
				};

				session_results.log.push(log_value);
			}
			// this.b0_text = "";
			// this.b1_text = "";
			// this.b2_text = "";
			// this.b3_text = "";
			// this.question = "";
		},
		next_trial() {
			if (this.input_disabled) return;
			//if (this.trial >= this.items.length) return;

			this.trial++;
			//console.log(
			//	"next trial: " + this.trial + " of " + this.items.length
			//);
			if (this.trial >= this.items.length) {
				this.end_test();
			}
		},
		set_test_items() {
			this.items = [
				{
					x: 2,
					y: 2,
					r: 4,
					set: "-",
					ord: 1,
					loc: 2,
					alt: [2, 3, 4, 5],
				},
				{
					x: 2,
					y: 4,
					r: 8,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [15, 8, 9, 12],
				},
				{
					x: 2,
					y: 6,
					r: 12,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [21, 12, 16, 15],
				},
				{
					x: 2,
					y: 8,
					r: 16,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [21, 16, 24, 20],
				},
				{
					x: 2,
					y: 10,
					r: 20,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [24, 27, 20, 32],
				},
				{
					x: 2,
					y: 12,
					r: 24,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [33, 40, 24, 48],
				},
				{
					x: 3,
					y: 3,
					r: 9,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [15, 9, 16, 25],
				},
				{
					x: 3,
					y: 5,
					r: 15,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [15, 12, 9, 16],
				},
				{
					x: 3,
					y: 7,
					r: 21,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [16, 15, 12, 21],
				},
				{
					x: 3,
					y: 9,
					r: 27,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [27, 16, 20, 21],
				},
				{
					x: 3,
					y: 11,
					r: 33,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [24, 40, 27, 33],
				},
				{
					x: 4,
					y: 2,
					r: 8,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [8, 9, 12, 15],
				},
				{
					x: 4,
					y: 4,
					r: 16,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [16, 9, 12, 15],
				},
				{
					x: 4,
					y: 6,
					r: 24,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [25, 16, 21, 24],
				},
				{
					x: 4,
					y: 8,
					r: 32,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [27, 36, 35, 32],
				},
				{
					x: 4,
					y: 10,
					r: 40,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [40, 45, 55, 48],
				},
				{
					x: 4,
					y: 12,
					r: 48,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [60, 55, 72, 48],
				},
				{
					x: 5,
					y: 3,
					r: 15,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [12, 16, 15, 9],
				},
				{
					x: 5,
					y: 5,
					r: 25,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [25, 24, 21, 35],
				},
				{
					x: 5,
					y: 7,
					r: 35,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [35, 32, 27, 36],
				},
				{
					x: 5,
					y: 9,
					r: 45,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [45, 49, 40, 48],
				},
				{
					x: 5,
					y: 11,
					r: 55,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [60, 63, 55, 48],
				},
				{
					x: 6,
					y: 2,
					r: 12,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [16, 15, 12, 21],
				},
				{
					x: 6,
					y: 4,
					r: 24,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [15, 24, 21, 16],
				},
				{
					x: 6,
					y: 6,
					r: 36,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [48, 35, 36, 32],
				},
				{
					x: 6,
					y: 8,
					r: 48,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [45, 40, 48, 36],
				},
				{
					x: 6,
					y: 10,
					r: 60,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [64, 55, 63, 60],
				},
				{
					x: 6,
					y: 12,
					r: 72,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [96, 72, 80, 77],
				},
				{
					x: 7,
					y: 3,
					r: 21,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [21, 16, 15, 12],
				},
				{
					x: 7,
					y: 5,
					r: 35,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [27, 32, 25, 35],
				},
				{
					x: 7,
					y: 7,
					r: 49,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [64, 63, 81, 49],
				},
				{
					x: 7,
					y: 9,
					r: 63,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [60, 49, 55, 63],
				},
				{
					x: 7,
					y: 11,
					r: 77,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [80, 96, 81, 77],
				},
				{
					x: 8,
					y: 2,
					r: 16,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [20, 21, 24, 16],
				},
				{
					x: 8,
					y: 4,
					r: 32,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [36, 32, 35, 27],
				},
				{
					x: 8,
					y: 6,
					r: 48,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [48, 45, 40, 49],
				},
				{
					x: 8,
					y: 8,
					r: 64,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [60, 63, 49, 64],
				},
				{
					x: 8,
					y: 10,
					r: 80,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [81, 77, 80, 72],
				},
				{
					x: 8,
					y: 12,
					r: 96,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [99, 80, 100, 96],
				},
				{
					x: 9,
					y: 3,
					r: 27,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [21, 27, 20, 16],
				},
				{
					x: 9,
					y: 5,
					r: 45,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [40, 48, 45, 49],
				},
				{
					x: 9,
					y: 7,
					r: 63,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [64, 80, 77, 63],
				},
				{
					x: 9,
					y: 9,
					r: 81,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [64, 81, 80, 77],
				},
				{
					x: 9,
					y: 11,
					r: 99,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [121, 120, 99, 100],
				},
				{
					x: 10,
					y: 2,
					r: 20,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [20, 24, 27, 32],
				},
				{
					x: 10,
					y: 4,
					r: 40,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [40, 55, 45, 48],
				},
				{
					x: 10,
					y: 6,
					r: 60,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [45, 48, 55, 60],
				},
				{
					x: 10,
					y: 8,
					r: 80,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [77, 64, 80, 72],
				},
				{
					x: 10,
					y: 10,
					r: 100,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [120, 100, 96, 99],
				},
				{
					x: 10,
					y: 12,
					r: 120,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [120, 100, 96, 99],
				},
				{
					x: 11,
					y: 3,
					r: 33,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [40, 33, 27, 24],
				},
				{
					x: 11,
					y: 5,
					r: 55,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [45, 55, 48, 40],
				},
				{
					x: 11,
					y: 7,
					r: 77,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [77, 96, 80, 81],
				},
				{
					x: 11,
					y: 9,
					r: 99,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [81, 99, 96, 100],
				},
				{
					x: 11,
					y: 11,
					r: 121,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [120, 100, 121, 99],
				},
				{
					x: 12,
					y: 2,
					r: 24,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [48, 33, 24, 40],
				},
				{
					x: 12,
					y: 4,
					r: 48,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [33, 40, 48, 24],
				},
				{
					x: 12,
					y: 6,
					r: 72,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [60, 72, 77, 80],
				},
				{
					x: 12,
					y: 8,
					r: 96,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [80, 96, 72, 77],
				},
				{
					x: 12,
					y: 10,
					r: 120,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [120, 99, 96, 100],
				},
				{
					x: 12,
					y: 12,
					r: 144,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [100, 121, 144, 120],
				},
				{
					x: 2,
					y: 3,
					r: 6,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [12, 10, 20, 6],
				},
				{
					x: 2,
					y: 5,
					r: 10,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [18, 10, 12, 14],
				},
				{
					x: 2,
					y: 7,
					r: 14,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [14, 18, 24, 20],
				},
				{
					x: 2,
					y: 9,
					r: 18,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [28, 22, 24, 18],
				},
				{
					x: 2,
					y: 11,
					r: 22,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [30, 22, 36, 44],
				},
				{
					x: 3,
					y: 2,
					r: 6,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [20, 6, 10, 12],
				},
				{
					x: 3,
					y: 4,
					r: 12,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [12, 20, 30, 18],
				},
				{
					x: 3,
					y: 6,
					r: 18,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [18, 20, 12, 14],
				},
				{
					x: 3,
					y: 8,
					r: 24,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [24, 30, 36, 28],
				},
				{
					x: 3,
					y: 10,
					r: 30,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [22, 36, 24, 30],
				},
				{
					x: 3,
					y: 12,
					r: 36,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [36, 60, 50, 44],
				},
				{
					x: 4,
					y: 3,
					r: 12,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [20, 12, 18, 30],
				},
				{
					x: 4,
					y: 5,
					r: 20,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [18, 20, 14, 28],
				},
				{
					x: 4,
					y: 7,
					r: 28,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [28, 24, 30, 20],
				},
				{
					x: 4,
					y: 9,
					r: 36,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [40, 42, 36, 44],
				},
				{
					x: 4,
					y: 11,
					r: 44,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [60, 44, 50, 54],
				},
				{
					x: 5,
					y: 2,
					r: 10,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [14, 12, 18, 10],
				},
				{
					x: 5,
					y: 4,
					r: 20,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [20, 12, 18, 14],
				},
				{
					x: 5,
					y: 6,
					r: 30,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [30, 40, 24, 28],
				},
				{
					x: 5,
					y: 8,
					r: 40,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [30, 36, 40, 42],
				},
				{
					x: 5,
					y: 10,
					r: 50,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [56, 60, 54, 50],
				},
				{
					x: 5,
					y: 12,
					r: 60,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [44, 36, 60, 50],
				},
				{
					x: 6,
					y: 3,
					r: 18,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [20, 12, 18, 14],
				},
				{
					x: 6,
					y: 5,
					r: 30,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [40, 30, 24, 28],
				},
				{
					x: 6,
					y: 7,
					r: 42,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [30, 40, 42, 36],
				},
				{
					x: 6,
					y: 9,
					r: 54,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [50, 44, 54, 42],
				},
				{
					x: 6,
					y: 11,
					r: 66,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [72, 70, 60, 66],
				},
				{
					x: 7,
					y: 2,
					r: 14,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [18, 24, 14, 20],
				},
				{
					x: 7,
					y: 4,
					r: 28,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [24, 18, 20, 28],
				},
				{
					x: 7,
					y: 6,
					r: 42,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [36, 42, 30, 40],
				},
				{
					x: 7,
					y: 8,
					r: 56,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [56, 50, 42, 54],
				},
				{
					x: 7,
					y: 10,
					r: 70,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [56, 66, 70, 60],
				},
				{
					x: 7,
					y: 12,
					r: 84,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [70, 84, 60, 66],
				},
				{
					x: 8,
					y: 3,
					r: 24,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [28, 36, 30, 24],
				},
				{
					x: 8,
					y: 5,
					r: 40,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [30, 40, 42, 36],
				},
				{
					x: 8,
					y: 7,
					r: 56,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [54, 56, 70, 50],
				},
				{
					x: 8,
					y: 9,
					r: 72,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [70, 72, 66, 56],
				},
				{
					x: 8,
					y: 11,
					r: 88,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [72, 90, 84, 88],
				},
				{
					x: 9,
					y: 2,
					r: 18,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [18, 28, 24, 22],
				},
				{
					x: 9,
					y: 4,
					r: 36,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [36, 30, 40, 42],
				},
				{
					x: 9,
					y: 6,
					r: 54,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [54, 44, 50, 42],
				},
				{
					x: 9,
					y: 8,
					r: 72,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [70, 66, 88, 72],
				},
				{
					x: 9,
					y: 10,
					r: 90,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [108, 84, 90, 88],
				},
				{
					x: 9,
					y: 12,
					r: 108,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [90, 84, 88, 108],
				},
				{
					x: 10,
					y: 3,
					r: 30,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [30, 22, 36, 24],
				},
				{
					x: 10,
					y: 5,
					r: 50,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [40, 50, 44, 36],
				},
				{
					x: 10,
					y: 7,
					r: 70,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [70, 56, 66, 60],
				},
				{
					x: 10,
					y: 9,
					r: 90,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [108, 88, 84, 90],
				},
				{
					x: 10,
					y: 11,
					r: 110,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [110, 108, 90, 88],
				},
				{
					x: 11,
					y: 2,
					r: 22,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [30, 36, 22, 44],
				},
				{
					x: 11,
					y: 4,
					r: 44,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [50, 54, 44, 36],
				},
				{
					x: 11,
					y: 6,
					r: 66,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [60, 70, 66, 72],
				},
				{
					x: 11,
					y: 8,
					r: 88,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [108, 90, 88, 110],
				},
				{
					x: 11,
					y: 10,
					r: 110,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [108, 88, 90, 110],
				},
				{
					x: 11,
					y: 12,
					r: 132,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [108, 110, 90, 132],
				},
				{
					x: 12,
					y: 3,
					r: 36,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [60, 44, 36, 50],
				},
				{
					x: 12,
					y: 5,
					r: 60,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [44, 60, 36, 50],
				},
				{
					x: 12,
					y: 7,
					r: 84,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [70, 84, 88, 90],
				},
				{
					x: 12,
					y: 9,
					r: 108,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [84, 88, 108, 90],
				},
				{
					x: 12,
					y: 11,
					r: 132,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [108, 90, 110, 132],
				},
			];
			/*this.items = [
				{
					x: 2,
					y: 3,
					r: 6,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [12, 10, 20, 6],
				},
				{
					x: 2,
					y: 4,
					r: 8,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [15, 8, 9, 12],
				},
				{
					x: 2,
					y: 5,
					r: 10,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [18, 10, 12, 14],
				},
				{
					x: 2,
					y: 6,
					r: 12,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [21, 12, 16, 15],
				},
				{
					x: 2,
					y: 7,
					r: 14,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [14, 18, 24, 20],
				},
				{
					x: 2,
					y: 8,
					r: 16,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [21, 16, 24, 20],
				},
				{
					x: 2,
					y: 9,
					r: 18,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [28, 22, 24, 18],
				},
				{
					x: 2,
					y: 10,
					r: 20,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [24, 27, 20, 32],
				},
				{
					x: 2,
					y: 11,
					r: 22,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [30, 22, 36, 44],
				},
				{
					x: 2,
					y: 12,
					r: 24,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [33, 40, 24, 48],
				},
				{
					x: 3,
					y: 2,
					r: 6,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [20, 6, 10, 12],
				},
				{
					x: 3,
					y: 3,
					r: 9,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [15, 9, 16, 25],
				},
				{
					x: 3,
					y: 4,
					r: 12,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [12, 20, 30, 18],
				},
				{
					x: 3,
					y: 5,
					r: 15,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [15, 12, 9, 16],
				},
				{
					x: 3,
					y: 6,
					r: 18,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [18, 20, 12, 14],
				},
				{
					x: 3,
					y: 7,
					r: 21,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [16, 15, 12, 21],
				},
				{
					x: 3,
					y: 8,
					r: 24,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [24, 30, 36, 28],
				},
				{
					x: 3,
					y: 9,
					r: 27,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [27, 16, 20, 21],
				},
				{
					x: 3,
					y: 10,
					r: 30,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [22, 36, 24, 30],
				},
				{
					x: 3,
					y: 11,
					r: 33,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [24, 40, 27, 33],
				},
				{
					x: 3,
					y: 12,
					r: 36,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [36, 60, 50, 44],
				},
				{
					x: 4,
					y: 2,
					r: 8,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [8, 9, 12, 15],
				},
				{
					x: 4,
					y: 3,
					r: 12,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [20, 12, 18, 30],
				},
				{
					x: 4,
					y: 4,
					r: 16,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [16, 9, 12, 15],
				},
				{
					x: 4,
					y: 5,
					r: 20,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [18, 20, 14, 28],
				},
				{
					x: 4,
					y: 6,
					r: 24,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [25, 16, 21, 24],
				},
				{
					x: 4,
					y: 7,
					r: 28,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [28, 24, 30, 20],
				},
				{
					x: 4,
					y: 8,
					r: 32,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [27, 36, 35, 32],
				},
				{
					x: 4,
					y: 9,
					r: 36,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [40, 42, 36, 44],
				},
				{
					x: 4,
					y: 10,
					r: 40,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [40, 45, 55, 48],
				},
				{
					x: 4,
					y: 11,
					r: 44,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [60, 44, 50, 54],
				},
				{
					x: 4,
					y: 12,
					r: 48,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [60, 55, 72, 48],
				},
				{
					x: 5,
					y: 2,
					r: 10,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [14, 12, 18, 10],
				},
				{
					x: 5,
					y: 3,
					r: 15,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [12, 16, 15, 9],
				},
				{
					x: 5,
					y: 4,
					r: 20,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [20, 12, 18, 14],
				},
				{
					x: 5,
					y: 5,
					r: 25,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [25, 24, 21, 35],
				},
				{
					x: 5,
					y: 6,
					r: 30,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [30, 40, 24, 28],
				},
				{
					x: 5,
					y: 7,
					r: 35,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [35, 32, 27, 36],
				},
				{
					x: 5,
					y: 8,
					r: 40,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [30, 36, 40, 42],
				},
				{
					x: 5,
					y: 9,
					r: 45,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [45, 49, 40, 48],
				},
				{
					x: 5,
					y: 10,
					r: 50,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [56, 60, 54, 50],
				},
				{
					x: 5,
					y: 11,
					r: 55,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [60, 63, 55, 48],
				},
				{
					x: 5,
					y: 12,
					r: 60,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [44, 36, 60, 50],
				},
				{
					x: 6,
					y: 2,
					r: 12,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [16, 15, 12, 21],
				},
				{
					x: 6,
					y: 3,
					r: 18,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [20, 12, 18, 14],
				},
				{
					x: 6,
					y: 4,
					r: 24,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [15, 24, 21, 16],
				},
				{
					x: 6,
					y: 5,
					r: 30,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [40, 30, 24, 28],
				},
				{
					x: 6,
					y: 6,
					r: 36,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [48, 35, 36, 32],
				},
				{
					x: 6,
					y: 7,
					r: 42,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [30, 40, 42, 36],
				},
				{
					x: 6,
					y: 8,
					r: 48,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [45, 40, 48, 36],
				},
				{
					x: 6,
					y: 9,
					r: 54,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [50, 44, 54, 42],
				},
				{
					x: 6,
					y: 10,
					r: 60,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [64, 55, 63, 60],
				},
				{
					x: 6,
					y: 11,
					r: 66,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [72, 70, 60, 66],
				},
				{
					x: 6,
					y: 12,
					r: 72,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [96, 72, 80, 77],
				},
				{
					x: 7,
					y: 2,
					r: 14,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [18, 24, 14, 20],
				},
				{
					x: 7,
					y: 3,
					r: 21,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [21, 16, 15, 12],
				},
				{
					x: 7,
					y: 4,
					r: 28,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [24, 18, 20, 28],
				},
				{
					x: 7,
					y: 5,
					r: 35,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [27, 32, 25, 35],
				},
				{
					x: 7,
					y: 6,
					r: 42,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [36, 42, 30, 40],
				},
				{
					x: 7,
					y: 7,
					r: 49,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [64, 63, 81, 49],
				},
				{
					x: 7,
					y: 8,
					r: 56,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [56, 50, 42, 54],
				},
				{
					x: 7,
					y: 9,
					r: 63,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [60, 49, 55, 63],
				},
				{
					x: 7,
					y: 10,
					r: 70,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [56, 66, 70, 60],
				},
				{
					x: 7,
					y: 11,
					r: 77,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [80, 96, 81, 77],
				},
				{
					x: 7,
					y: 12,
					r: 84,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [70, 84, 60, 66],
				},
				{
					x: 8,
					y: 2,
					r: 16,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [20, 21, 24, 16],
				},
				{
					x: 8,
					y: 3,
					r: 24,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [28, 36, 30, 24],
				},
				{
					x: 8,
					y: 4,
					r: 32,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [36, 32, 35, 27],
				},
				{
					x: 8,
					y: 5,
					r: 40,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [30, 40, 42, 36],
				},
				{
					x: 8,
					y: 6,
					r: 48,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [48, 45, 40, 49],
				},
				{
					x: 8,
					y: 7,
					r: 56,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [54, 56, 70, 50],
				},
				{
					x: 8,
					y: 8,
					r: 64,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [60, 63, 49, 64],
				},
				{
					x: 8,
					y: 9,
					r: 72,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [70, 72, 66, 56],
				},
				{
					x: 8,
					y: 10,
					r: 80,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [81, 77, 80, 72],
				},
				{
					x: 8,
					y: 11,
					r: 88,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [72, 90, 84, 88],
				},
				{
					x: 8,
					y: 12,
					r: 96,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [99, 80, 100, 96],
				},
				{
					x: 9,
					y: 2,
					r: 18,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [18, 28, 24, 22],
				},
				{
					x: 9,
					y: 3,
					r: 27,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [21, 27, 20, 16],
				},
				{
					x: 9,
					y: 4,
					r: 36,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [36, 30, 40, 42],
				},
				{
					x: 9,
					y: 5,
					r: 45,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [40, 48, 45, 49],
				},
				{
					x: 9,
					y: 6,
					r: 54,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [54, 44, 50, 42],
				},
				{
					x: 9,
					y: 7,
					r: 63,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [64, 80, 77, 63],
				},
				{
					x: 9,
					y: 8,
					r: 72,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [70, 66, 88, 72],
				},
				{
					x: 9,
					y: 9,
					r: 81,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [64, 81, 80, 77],
				},
				{
					x: 9,
					y: 10,
					r: 90,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [108, 84, 90, 88],
				},
				{
					x: 9,
					y: 11,
					r: 99,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [121, 120, 99, 100],
				},
				{
					x: 9,
					y: 12,
					r: 108,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [90, 84, 88, 108],
				},
				{
					x: 10,
					y: 2,
					r: 20,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [20, 24, 27, 32],
				},
				{
					x: 10,
					y: 3,
					r: 30,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [30, 22, 36, 24],
				},
				{
					x: 10,
					y: 4,
					r: 40,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [40, 55, 45, 48],
				},
				{
					x: 10,
					y: 5,
					r: 50,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [40, 50, 44, 36],
				},
				{
					x: 10,
					y: 6,
					r: 60,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [45, 48, 55, 60],
				},
				{
					x: 10,
					y: 7,
					r: 70,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [70, 56, 66, 60],
				},
				{
					x: 10,
					y: 8,
					r: 80,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [77, 64, 80, 72],
				},
				{
					x: 10,
					y: 9,
					r: 90,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [108, 88, 84, 90],
				},
				{
					x: 10,
					y: 10,
					r: 100,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [120, 100, 96, 99],
				},
				{
					x: 10,
					y: 11,
					r: 110,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [110, 108, 90, 88],
				},
				{
					x: 10,
					y: 12,
					r: 120,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [120, 100, 96, 99],
				},
				{
					x: 11,
					y: 2,
					r: 22,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [30, 36, 22, 44],
				},
				{
					x: 11,
					y: 3,
					r: 33,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [40, 33, 27, 24],
				},
				{
					x: 11,
					y: 4,
					r: 44,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [50, 54, 44, 36],
				},
				{
					x: 11,
					y: 5,
					r: 55,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [45, 55, 48, 40],
				},
				{
					x: 11,
					y: 6,
					r: 66,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [60, 70, 66, 72],
				},
				{
					x: 11,
					y: 7,
					r: 77,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [77, 96, 80, 81],
				},
				{
					x: 11,
					y: 8,
					r: 88,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [108, 90, 88, 110],
				},
				{
					x: 11,
					y: 9,
					r: 99,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [81, 99, 96, 100],
				},
				{
					x: 11,
					y: 10,
					r: 110,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [108, 88, 90, 110],
				},
				{
					x: 11,
					y: 11,
					r: 121,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [120, 100, 121, 99],
				},
				{
					x: 11,
					y: 12,
					r: 132,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [108, 110, 90, 132],
				},
				{
					x: 12,
					y: 2,
					r: 24,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [48, 33, 24, 40],
				},
				{
					x: 12,
					y: 3,
					r: 36,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [60, 44, 36, 50],
				},
				{
					x: 12,
					y: 4,
					r: 48,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [33, 40, 48, 24],
				},
				{
					x: 12,
					y: 5,
					r: 60,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [44, 60, 36, 50],
				},
				{
					x: 12,
					y: 6,
					r: 72,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [60, 72, 77, 80],
				},
				{
					x: 12,
					y: 7,
					r: 84,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [70, 84, 88, 90],
				},
				{
					x: 12,
					y: 8,
					r: 96,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [80, 96, 72, 77],
				},
				{
					x: 12,
					y: 9,
					r: 108,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [84, 88, 108, 90],
				},
				{
					x: 12,
					y: 10,
					r: 120,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [120, 99, 96, 100],
				},
				{
					x: 12,
					y: 11,
					r: 132,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [108, 90, 110, 132],
				},
				{
					x: 12,
					y: 12,
					r: 144,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [100, 121, 144, 120],
				},
				{
					x: 2,
					y: 2,
					r: 4,
					set: "-",
					ord: 1,
					loc: 2,
					alt: [2, 3, 4, 5],
				},
			];*/

			/*this.item_test_order = [
				35, 84, 34, 41, 115, 23, 40, 17, 13, 19, 6, 110, 39, 108, 111,
				94, 103, 8, 33, 18, 49, 16, 61, 26, 60, 24, 95, 63, 82, 67, 114,
				118, 54, 106, 21, 71, 64, 83, 107, 70, 20, 9, 99, 56, 74, 14,
				36, 31, 90, 65, 88, 50, 98, 97, 72, 109, 78, 11, 48, 68, 45, 75,
				1, 38, 96, 5, 100, 22, 28, 59, 89, 43, 51, 73, 104, 77, 112, 3,
				62, 32, 47, 69, 81, 15, 7, 85, 57, 29, 10, 117, 116, 52, 119,
				55, 87, 79, 25, 105, 86, 93, 92, 102, 101, 46, 4, 27, 12, 113,
				53, 91, 44, 80, 58, 42, 0, 30, 66, 76, 37, 2,
			];*/

			/*this.item_test_order_old = [
				114, 62, 119, 77, 104, 65, 10, 84, 17, 41, 88, 57, 106, 8, 45,
				83, 21, 7, 19, 9, 40, 34, 67, 5, 25, 96, 23, 105, 68, 87, 115,
				47, 79, 18, 2, 0, 46, 118, 63, 12, 6, 3, 113, 71, 110, 60, 103,
				80, 58, 20, 51, 72, 91, 108, 32, 93, 43, 86, 112, 69, 24, 22,
				78, 27, 36, 49, 116, 29, 59, 81, 39, 35, 54, 73, 44, 61, 117,
				75, 56, 95, 111, 82, 28, 37, 1, 85, 98, 94, 50, 70, 48, 109,
				100, 99, 15, 26, 64, 53, 52, 42, 66, 102, 101, 16, 74, 97, 33,
				38, 13, 55, 11, 14, 76, 107, 30, 4, 90, 89, 31, 92,
			];*/

			/*this.item_test_order = [
				120, 62, 93, 77, 104, 65, 10, 84, 17, 41, 88, 57, 106, 8, 45,
				83, 21, 7, 19, 9, 40, 34, 67, 5, 25, 96, 23, 105, 68, 114, 87,
				115, 47, 79, 18, 2, 0, 46, 118, 63, 12, 6, 3, 113, 71, 110, 60,
				103, 80, 58, 20, 51, 72, 91, 108, 32, 119, 43, 86, 112, 69, 24,
				22, 78, 27, 36, 49, 116, 29, 59, 81, 39, 35, 54, 73, 44, 61,
				117, 75, 56, 95, 111, 82, 28, 37, 1, 85, 98, 94, 50, 70, 48,
				109, 100, 99, 15, 26, 64, 53, 52, 42, 66, 102, 101, 16, 74, 97,
				33, 38, 13, 55, 11, 14, 76, 107, 30, 4, 90, 89, 31, 92,
			];
			*/
			this.item_test_order = [
				0, 62, 93, 77, 104, 65, 10, 84, 17, 41, 88, 57, 106, 8, 45, 83,
				21, 7, 19, 9, 40, 34, 67, 5, 25, 96, 23, 105, 68, 114, 87, 115,
				47, 79, 18, 2, 46, 118, 63, 12, 6, 3, 113, 71, 110, 60, 103, 80,
				58, 20, 51, 72, 91, 108, 32, 119, 43, 86, 112, 69, 24, 22, 78,
				27, 36, 49, 116, 29, 59, 81, 39, 35, 54, 73, 44, 61, 117, 75,
				56, 95, 111, 82, 28, 37, 1, 85, 98, 94, 50, 70, 48, 109, 100,
				99, 15, 26, 64, 53, 52, 42, 66, 102, 101, 16, 74, 97, 33, 38,
				13, 55, 11, 14, 76, 107, 30, 4, 90, 89, 31, 92, 120,
			];
		},
	},
	mounted() {
		this.set_test_items();

		this.start_time = Date.now();

		const config = this.$store.state.progress.conf || "";
		this.pilot = config == "reb";

		this.beforeUnloadHandler = () => {
			//console.log("before unload");
			this.abortAndSave();
			// Optionally, show a confirmation dialog
			// event.returnValue = 'Are you sure you want to leave?';
		};

		// Spara så att nästa session alltid är exakt DENNA + 1
		// oavsett om data sparats flera gånger innnan avslut.
		this.initial_session = this.$store.state.progress.session || 1;

		window.addEventListener("beforeunload", this.beforeUnloadHandler);

		//console.log(this.item_test_order.length);
		//console.log(this.items.length);

		//store.commit("setTaskMode", "ready_no_exit");
		store.commit("setTaskMode", "playing");
		store.commit("taskPhase", 0);
		this.$parent.show_buttons = false;

		this.show_buttons = false;
		this.input_disabled = true;
		//setTimeout(() => {
		this.trial = 0;
		this.input_disabled = false;
		this.show_buttons = true;
		//}, 2000);
	},
};
</script>
